<template>
  <div class="">
    <div class="terms">
      <div class="icon">
        <InlineSvg :src="require('../../assets/public/terms.svg')"></InlineSvg>
      </div>
      <div class="terms-text" v-html="privacyPolicy.privacyPolicy">

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";

export default {
  name: "Terms",
  data() {
    return {
      privacyPolicy: ''
    }
  },
  components: {InlineSvg, Footer},
  methods: {
    async getInfo() {
      const res = await this.$axios.get('/exchange-info')
      this.privacyPolicy = res.baseDTO
    }
  },
  mounted() {
    this.getInfo()
  }
};
</script>

<style lang="scss" scoped>
.terms {
  padding: 0 120px;
  padding-bottom: 50px;
  background: #0a1128;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .terms-text {
    color: white;
    font-size: clamp(16px, 1vw, 12px);
    padding: 40px 24px;
    padding-bottom: 128px;
    background: #141b31;
    border-radius: 16px;

    .nobitex-title {
      margin-top: 8%;
      text-align: center;
      color: #febc00;
    }
  }
}

@media screen and (max-width: 1000px) {
  .terms {
    padding: 20px;
  }
}

@media screen and(max-width: 500px) {
  .terms {
    .icon {
      transform: scale(0.5);
    }
  }
}
</style>
