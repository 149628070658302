<template>
  <div class="otc">
    <div class="otc-box">
      <div class="buttons-container">
        <div class="btn-changes">

          <div class="buttons">
            <button @click="buy = true;data.orderType=='EXCHANGE_BUY';getPrice();tomanTetherWallet()" :class="{ 'active-buy': buy }">
              خرید
            </button>
            <button
                @click.prevent="buy = false;data.orderType=='EXCHANGE_SELL';getPrice();tomanTetherWallet()"
                :class="{ 'active-sell': !buy }"
            >
              فروش
            </button>
          </div>
        </div>
        <div class="btn-changes">

          <div class="buttons">
            <button @click="toman = true;tomanTetherWallet()" :class="{ 'activated': toman }">
              تومان
            </button>
            <button
                @click.prevent="toman = false;tomanTetherWallet()"
                :class="{ 'activated': !toman }"
            >
              تتر
            </button>
          </div>
        </div>
      </div>
      <div class="first-row">
        <div class="first-column">
          <div class="btn-changes">


            <div class="changes">
              <button @click="activeDropdown" class="dropdown-btn">
                <span class="price">
                  <img :src="'/coins/' + logo + '.png'" alt="bit-logo"/>
                  {{ select }}
                </span>
                <img src="@/assets/public/dropselector.svg" alt=""/>
              </button>
              <div class="dropdown" v-if="dropdown">
                <template>
                  <div
                      class="drop-row"
                      v-for="(coin, index) in ((!toman?coins.filter(a=>a.coin !=='TETHER'):coins))"
                      @click.prevent="selectItem(index)"
                      :key="index"
                  >
                    <img
                        :src="'/coins/' + coin.coin + '.png'"
                        alt="coins"
                        width="38"
                    />
                    <span>
                      {{ coin.persianName }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="inventory">
            <div class="inventory-data">
              <p>موجودی:</p>
              <p class="inventory-amount">
                {{
                  buy
                      ? $toLocal(BuywalletBalance.credit, $decimal["TOMAN"])
                      : $toLocal(credit, $decimal[select])
                }}
                <span v-if="(buy )&& (BuywalletBalance.credit<=0)">{{BuywalletBalance.credit}}</span>
                <span v-if="!buy&&credit<=0">{{credit}}</span>
              </p>
              <p v-if="buy"> {{ this.toman ? 'تومان' : 'تتر' }}</p>


              <p v-if="!buy">{{ select }}</p>


              <button class="refresh" @click="tomanTetherWallet()">
                <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M15.8484 2.52295V6.63164H11.8711"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <path
                      d="M1.26367 13.4793V9.37061H5.24094"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <path
                      d="M2.9275 5.94692C3.26369 4.96548 3.83507 4.08801 4.58832 3.39639C5.34157 2.70477 6.25214 2.22154 7.23507 1.99179C8.218 1.76204 9.24125 1.79326 10.2093 2.08254C11.1774 2.37181 12.0588 2.90971 12.7712 3.64605L15.847 6.6317M1.26367 9.37083L4.33943 12.3565C5.05186 13.0928 5.93324 13.6307 6.90133 13.92C7.86942 14.2093 8.89267 14.2405 9.8756 14.0107C10.8585 13.781 11.7691 13.2978 12.5224 12.6061C13.2756 11.9145 13.847 11.0371 14.1832 10.0556"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <input
                class="normal-input"
                type="text"
                :placeholder="
                !buy ? 'دریافت میکنید (تومان)' : ' می پردازید (تومان)'
              "
                @input="inputsFun('totalPrice')"
                v-model="data.totalPrice"
            />
          </div>
          <input
              class="normal-input"
              placeholder="مقدار کل سفارش (تومان)"
              @input="inputsFun('wage')"
              type="text"
              v-model="data.totalPrice"
          />
        </div>
        <div class="second-column">
          <div class="price">
            <p>قیمت فعلی:</p>
            <p :class="{ sell: !buy, buy: buy }">
              {{ $toLocal(data.unitPrice, 0) }}
              {{ this.toman ? 'تومان' : 'تتر' }}
            </p>
          </div>
          <div class="info">
            <input
                class="normal-input"
                type="text"
                :placeholder="
                !buy ? `می پردازید (${select})` : `دریافت می کنید (${select})`
              "
                @input="inputsFun('amount')"
                v-model="data.amount"
            />
            <div class="info-prices">
              <div class="fee">
                <p>کارمزد انتقال :</p>
                <p class="fee-price">{{ data.wage }}</p>
              </div>
              <div class="total">
                <p>مجموع پرداخت :</p>
                <p class="total-price">

                  {{ this.toman ? 'تومان' : 'تتر' }}

                  {{ data.totalPrice }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="second-row">
        <button
            :disabled="!!buyReadOnly"
            @click="placed = true"
            class="buy-btn"
            v-if="buy"
        >
          {{ buyReadOnly ? buyReadOnly : buttons[0].title }}
        </button>
        <button
            :disabled="!!sellReadOnly"
            @click="placed = true"
            class="sell-btn"
            v-else
        >
          {{ sellReadOnly ? sellReadOnly : buttons[1].title }}
        </button>
      </div>
    </div>
    <place-order
        v-if="placed"
        @close="placed = false"
        @accept="sendData"
        :unitPrice="data.unitPrice"
        :totalPrice="data.totalPrice"
        :amount="data.amount"
        :coin="data.destinationCoin"
        :type="this.buy"
    />
    <ex-modal
        v-if="success"
        :unitprice="data.unitPrice"
        :totalprice="data.totalPrice"
        :ordertype="data.orderType"
        :amount="data.amount"
        @closeModal="success = false"
    />
  </div>
</template>

<script>
// import Input from "../../tools/input.vue";
// import vuescroll from "vuescroll";
import {Loop} from "@/library/reuseableFunction";
import exModal from "@/components/profile/otc/exModal";
import PlaceOrder from "./PlaceOrder.vue";

export default {
  components: {
    exModal,
    PlaceOrder,
    // Input,
    // vuescroll
  },

  data() {
    return {
      buttons: [
        {
          title: "ثبت سفارش خرید",
          class: "buy-btn",
        },
        {
          title: "ثبت سفارش فروش",
          class: "sell-btn",
        },
      ],
      buy: true,
      dropdown: false,
      logo: "",
      select: "",
      price: "",
      placed: false,
      dropItems: [
        {
          name: "بیت کوین",
          img: require("../../../assets/coins/BTC.png"),
          price: "",
        },
      ],
      temptData: {},
      isTyping: false,
      disable: true,
      loop: undefined,
      sellAmount: "",
      coin: "BITCOIN",
      credit: "",
      walletBalance: {},
      loading: false,
      prices: "",
      beebitexWallet: true,
      netDrop: false,
      selected_net: "",
      nets: [],
      coins: [],
      txIdIsNeeded: false,
      totalPrice: "",
      exModal: false,
      success: false,
      BuywalletBalance: "",
      SellwalletBalance: "",
      minAmountExchange: "",
      maxAmountExchange: "",
      data: {
        marketType: "TRON_TOMAN",
        amount: null,
        sourceCoin: "TOMAN",
        destinationCoin: "BITCOIN",
        orderType: "EXCHANGE_BUY",
        // tokenType: "",
        // destinationWallet: "",
        unitPrice: null,
        totalPrice: null,
        dollar: null,
        wage: 0.3,
      },
      placeholderCrypto: "دریافت میکند",
      placeholderToman: "می پردازد",
      amount: "",

      drop: false,
      modal: false,
      alive: true,
      toman: true,
      wallet: [{}],
      fromAmount: true,
      calculating: false,
    };
  },

  computed: {
    sellReadOnly() {
      let error = "";
      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
        // this.disableSell = true
      } else if (this.$S2N(this.data.amount) < this.minAmountExchange) {
        error = "حداقل مقدار رعایت نشده";
        // this.disableSell = true
      } else if (
          (this.buy &&
              this.$S2N(this.data.totalPrice) > this.BuywalletBalance.credit) ||
          (!this.buy && this.$S2N(this.data.amount) > this.walletBalance.credit)
      ) {
        error = "مقدار بیش از موجودی شماست";
        // this.sellAmount = 'مقدار بیش از موجودی شماست'
        // this.disableSell = true
      } else if (this.$S2N(this.data.amount) > this.maxAmountExchange) {
        error = "حداکثر مقدار رعایت نشده";
        // this.sellAmount = 'حداکثر مقدار رعایت نشده'
        // this.disableSell = true
      } else if (this.calculating) {
        error = "لطفا صبر کنید ...";
      }
      return error;
      //  else {
      //     this.disableSell = false
      //     this.sellAmount = ''
      // }
    },
    buyReadOnly() {
      let error = "";
      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
      }
      else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
        error = "حداقل مقدار رعایت نشده";
      }
      else if (
          this.$A2N(this.data.totalPrice) > this.BuywalletBalance.credit
      ) {
        error = "مقدار بیش از موجودی شماست";
      }

      else if (this.$S2N(this.data.amount) > this.$A2N(this.maxAmountExchange)) {
        error = "حداکثر مقدار رعایت نشده";
      }

      else if (!this.beebitexWallet) {
        if (this.data.destinationWallet == "") {
          error = " آدرس کیف پول اجباری است ";
        } else if (this.data.tokenType == "") {
          error = "انخاب شبگه اجباری است";
        }
      } else if (this.calculating) {
        error = "لطفا صبر کنید ...";
      }

      return error;
    },
  },
  methods: {
    async unitFun() {
      this.data.totalPrice = "";
      if (this.loop) {
        this.loop.stop();
      }
      this.toman = !this.toman;
      // $emit('changeUnit',toman);
      // calcTotalPrice();calcAmount();
      await this.tomanTetherWallet();
      this.beforeLoop();
    },
    async inputsFun(e) {
      this.calculating = true;
      if (e === "amount") {
        this.data.amount = this.$toLocal(
            this.data.amount,
            this.$decimal[this.coin]
        );
      } else {
        this.data.totalPrice = this.$toLocal(
            this.data.totalPrice,
            this.$decimal["TOMAN"]
        );
      }

      this.fromAmount = e === "amount";
      if (this.loop) {
        this.loop.stop();
      }
      const temp = this.data[e];
      await this.$sleep(500);
      if (temp === this.data[e]) {
        this.beforeLoop();
      }
    },

    async totalPriceFun() {
      // this.calcAmount();
      // this.sellReadOnly()
      // this.data.totalOfAll = this.$toLocal(this.data.totalPrice * this.data.amount, this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
      this.calculating = true;
      let total = this.data.amount * this.data.totalPrice * this.data.dollar;
      this.data.totalPrice = this.$toLocal(total, 1);
    },
    // amountFun() {

    //     this.calcTotalPrice()
    //     this.data.amount = this.$toLocal(this.data.amount)
    //     this.sellReadOnly()
    // },
    checkModal() {
      if (this.buy) {
        this.data.marketType =
            this.coin + "_" + (this.toman ? "TOMAN" : "TETHER");
        this.data.destinationCoin = this.coin;
        this.data.orderType = "EXCHANGE_BUY";
        this.exModal = true;
      } else {
        this.data.marketType =
            this.coin + "_" + (this.toman ? "TOMAN" : "TETHER");
        this.data.destinationCoin = this.coin;
        this.data.orderType = "EXCHANGE_SELL";
        this.exModal = true;
      }
    },
    async sendData() {
      this.placed = false;
      this.temptData = JSON.parse(JSON.stringify(this.data));
      this.temptData.amount = this.$S2N(this.temptData.amount);
      this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice);
      this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice);
      this.exModal = false;
      if (this.buy) {
        this.state.loading = true;
        await this.$axios.post("/exchange/buy", this.temptData);
        this.success = true;
      } else {
        delete this.temptData.destinationWallet;
        delete this.temptData.tokenType;
        this.temptData.orderType = "EXCHANGE_SELL";
        this.data.orderType = "EXCHANGE_SELL";
        this.state.loading = true;
        await this.$axios.post("/exchange/sell", this.temptData);
        this.success = true;
        this.getWallets();
        this.tomanTetherWallet();
        this.state.loading = false;
      }
    },
    //getting wallets
    async getWallets() {
      this.state.loading = true;
      const [res] = await this.$http.get(
          `/wallets/customer-addresses?walletType=EXCHANGE`,
          {
            params: {
              coins: `${this.coin}`,
            },
          }
      );
      if (res) {
        this.walletBalance = res[this.coin];
        this.credit = this.walletBalance.credit;
      }
      this.state.loading = false;
    },
    async getPrice() {

      const res = await this.$axios.get(
          // "coins/exchange-price/"+ this.toman? 'usd' : 'usdt' +"?includeWeeklyChange=false&destCoinSymbol=" +
          //   this.coin

          `coins/exchange-price/${this.toman ? 'usd' : 'usdt'}?includeWeeklyChange=false&destCoinSymbol=${this.coin}`
      );
      this.prices = res;
      this.data.unitPrice = this.prices.find(
          (a) => a.destCoinSymbol == this.coin
      )[this.buy ? "buyPrice" : "sellPrice"];
      if (this.fromAmount) {
        this.calcTotalPrice();
      } else {
        this.calcAmount();
      }
// this.beforeLoop()
      this.calculating = false;

    },
    async tomanTetherWallet() {
      // this.loading = true
      const [res2] = await this.$http.get(
          `/wallets/customer-addresses?walletType=EXCHANGE&coins=${this.toman ? "TOMAN" : "TETHER"}`
      );
      if (res2) {
        this.BuywalletBalance = this.toman ? res2["TOMAN"] : res2["TETHER"];
      }
      this.beforeLoop()
      // this.loading = false
    },
    calcTotalPrice() {
      this.data.totalPrice = this.$toLocal(
          this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice),
          this.$decimal[this.toman ? "TOMAN" : "TETHER"]
      );
    },
    calcAmount() {
      this.data.amount = this.$toLocal(
          this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice),
          this.$decimal[this.coin]
      );
    },
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.coins = res.content.filter((name) => name.coin !== "TOMAN");
      this.minAmountExchange = this.coins.find(a => a.coin == this.coin).minAmountExchange
      this.maxAmountExchange = this.coins.find(a => a.coin == this.coin).maxAmountExchange
      let coin = this.coins.find(a => a.coin == this.coin);
this.data.wage = coin.tokenTypeDetails[0].withdrawalFee




console.log(this.maxAmountExchange
)
      this.state.loading = false;
    },

    titlesclick() {
      if (this.buyTtile) {
        return true;
      } else {
        this.buyTtile = true;
        this.sellTitle = false;
      }
    },
    async selectedCoin(value) {
      this.data.amount = 0;
      this.coin = value.destCoinSymbol;
      await this.getWallets();
      // this.getNets()
      this.beforeLoop();
    },
    //
    // MinMaxAmount() {
    //   this.minAmountExchange = this.coins.find(
    //       (a) => a.coin == this.coin
    //   ).minAmountExchange;
    //   this.maxAmountExchange = this.coins.find(
    //       (a) => a.coin == this.coin
    //   ).maxAmountExchange;
    //
    // },
    beforeLoop() {
      this.state.loading = false;
      this.calculating = true;
      if (this.loop) {
        this.loop.stop();
      }
      this.loading = true;
      if (this.state.loop) {
        this.loop = new Loop(
            this.getPrice,
            5000,
            "getPrice" + (this.toman ? "Toman" : "Tether")
        );
        this.loop.start();
      } else {
        this.getPrice();
      }
    },

    selectItem(index) {
      let market = this.coins[index].coin;
      this.state.loading = true;
      this.getPrice();
      this.select = this.coins[index].persianName;
      this.coin = this.coins[index].coin;
      this.logo = this.coins[index].coin;
      this.data.destinationCoin = this.coin;
      this.data.unitPrice = this.prices[index].price * this.data.dollar;
      this.dropdown = false;
      this.state.loading = false;
      this.data.marketType = `${market}_TOMAN`;
      this.getWallets();
      this.getCoins()
    },
    activeDropdown() {
      this.dropdown = !this.dropdown;
      window.addEventListener("click", (event) => {
        if (!event.target.closest(".dropdown,.dropdown-btn")) {
          this.dropdown = false;
        }
      });
    },
  },
  beforeDestroy() {
    if (this.loop) {
      this.loop.stop();
    }
  },

  async mounted() {
    await this.getWallets();
    this.getPrice();
    await this.getCoins();
    await this.tomanTetherWallet();
    // await this.MinMaxAmount();
    this.beforeLoop();
    this.select = this.coins[0].persianName;
    this.logo = this.coins[0].coin;
    this.data.marketType = `${this.coins[0].coin}_TOMAN`;
    // this.coin = this.coins[0].coin;
  },
};
</script>


<style lang="scss">
.activated {
  border-radius: 8px;

  background: #279bc0 !important;
}

.buttons-container {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  .btn-changes {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .buttons {
      width: 100%;
    }
  }

  .btn-changes:first-child {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: stretch;

    .buttons {
      max-width: unset;
      width: 100%;
    }
  }
}

.btn-changes {
  // display: flex;
  // flex-direction: column;
  // row-gap: 32px;
  // width:50%;
  .buttons {
    display: flex;
    overflow: hidden;
    background: #9c9fc029;
    min-height: 36px;
    border-radius: 8px;
    font-size: clamp(16px, 1vw, 12px);
    max-width: 300px;

    button {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: 0;
      background: transparent;
      flex-grow: 1;

      .sell {
        position: absolute;
      }
    }

    .active-buy {
      border-radius: 8px;
      background: #26c22c;
    }

    .active-sell {
      border-radius: 8px;
      background: #e53756;
    }
  }

  .changes {
    border: 1px solid #279bc0;
    border-radius: 4px;
    position: relative;

    .dropdown-btn {
      max-height: 36px;

      .price {
        display: flex;
        column-gap: 4px;
        font-size: clamp(12px, 0.5vw, 16px);

        > img {
          width: 20px;
          height: 20px;
        }
      }

      span {
        padding: 0px 8px;
      }

      width: 100%;
      padding: 8px;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      color: white;
    }

    .dropdown {
      cursor: pointer;
      height: 200px;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #141b31;
      border-radius: 4px;
      padding: 10px;
      row-gap: 10px;
      top: 40px;
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .drop-row {
        display: flex;

        align-items: center;
        column-gap: 10px;

        font-size: 16px;
        color: white;
        width: 100%;

        > img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .dropdown::-webkit-scrollbar {
      display: none;
    }
  }
}


.normal-input {
  color: white;
  padding: 0 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  background: transparent;
}

.scroll-bar {
  max-height: 60vh;
}

.__rail-is-vertical {
  left: 20px !important;
  right: unset !important;
  background: #19196f;
}

.__bar-wrap-is-vertical {
  background: #0f1640;
}

.__bar-is-vertical {
  background: #0f1640;
}

.otc {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .otc-box {
    max-width: 648px;
    width: 100%;
    border-radius: 8px;
    padding: 20px;
    background: #0f1640;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    flex-wrap: wrap;

    .first-row {
      width: 100%;
      column-gap: 52px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .first-column {
        flex: 1;
        flex-wrap: wrap;
        row-gap: 52px;

        display: flex;
        flex-direction: column;

        .btn-changes {
          display: flex;
          flex-direction: column;
          row-gap: 32px;
          width: 100% !important;

          .buttons {
            display: flex;
            overflow: hidden;
            background: #9c9fc029;
            min-height: 36px;
            border-radius: 8px;
            font-size: clamp(16px, 1vw, 12px);
            max-width: 300px;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              border: 0;
              background: transparent;
              flex-grow: 1;

              .sell {
                position: absolute;
              }
            }

            .active-buy {
              border-radius: 8px;
              background: #26c22c;
            }

            .active-sell {
              border-radius: 8px;
              background: #e53756;
            }
          }

          .changes {
            border: 1px solid #279bc0;
            border-radius: 4px;
            position: relative;

            .dropdown-btn {
              max-height: 36px;

              .price {
                display: flex;
                column-gap: 4px;
                font-size: clamp(12px, 0.5vw, 16px);

                > img {
                  width: 20px;
                  height: 20px;
                }
              }

              span {
                padding: 0px 8px;
              }

              width: 100%;
              padding: 8px;
              border: none;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: transparent;
              color: white;
            }

            .dropdown {
              cursor: pointer;
              height: 200px;
              position: absolute;
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #141b31;
              border-radius: 4px;
              padding: 10px;
              row-gap: 10px;
              top: 40px;
              overflow: scroll;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */

              .drop-row {
                display: flex;

                align-items: center;
                column-gap: 10px;

                font-size: 16px;
                color: white;
                width: 100%;

                > img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .dropdown::-webkit-scrollbar {
              display: none;
            }
          }
        }

        .inventory {
          row-gap: 13px;
          align-items: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          column-gap: 4px;

          p {
            color: #9c9fc0;
            font-size: clamp(12px, 1vw, 16px);
          }

          .inventory-data {
            width: 100%;
            display: flex;
            column-gap: 4px;
          }

          .inventory-amount {
            color: #279bc0;
          }

          .refresh {
            display: flex;
            align-items: center;
            background: transparent;
            border: 0;
          }
        }
      }

      .second-column {
        flex: 1;
        display: flex;
        flex-direction: column;

        row-gap: 103px;

        .price {
          // margin-top: 70px;
          display: flex;
          column-gap: 9px;

          p {
            font-size: clamp(12px, 1vw, 16px);
          }

          .green {
            color: #339e00;
            font-size: clamp(14px, 1vw, 18px);
            font-weight: 500;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          row-gap: 49px;

          .info-prices {
            row-gap: 8px;

            p {
              font-size: clamp(12px, 1vw, 16px);
            }

            .fee {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .fee-price {
                color: #9c9fc0;
              }
            }

            .total {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .total-price {
                color: #9c9fc0;
              }
            }
          }
        }
      }
    }

    .second-row {
      width: 100%;

      .buy-btn,
      .sell-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        min-height: 44px;
        border: 0;
        border-radius: 8px;
        margin-bottom: 8px;
      }

      .buy-btn {
        background: #26c22c;
      }

      .sell-btn {
        background: #e53756;
      }
    }
  }
}

@media (max-width: 437px) {
  .first-row {
    .second-column {
      row-gap: 5px !important;
    }
  }
}
</style>
