<template>
  <!--  <div class="send-mail container">-->
  <div class="email-verification">
    <div class="form">
      <h1>تایید آدرس ایمیل</h1>
      <p>کد شش رقمی ارسال شده به {{ otp.email }} را وارد کنید.</p>
      <div class="verification">
        <CodeInput
            class="code-input"
            @change="onChange"
            @complete="submit"
            :fields="num"
            ref="code-input"
        />
        <div class="timer">
          <div class="timer" v-if="finished">
            <a class="timer">0:00</a>
          </div>
          <div class="timer" v-else>
            {{ display }}
          </div>
        </div>
      </div>
      <button id="btn" v-if="!finished" @click.prevent="submit">تایید</button>
      <button id="btn2" v-if="finished" @click.prevent="send">ارسال مجدد</button>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {DateTime, Duration} from "luxon";

export default {
  components: {CodeInput},

  data() {
    return {
      //timer
      now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 120}),
      tick: null,
      num: 6,
      //otp
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username"),
        mobile: this.state.userInfo.mobile,
        // google: this.state.userInfo.google,
      },
    };
  },

  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods: {
    countDownTimer() {
      if (!this.finished) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
      }
    },
    async send() {
      this.now = DateTime.local().set({milliseconds: 0});
      this.end = DateTime.local()
          .set({milliseconds: 0})
          .plus({seconds: 120});

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({milliseconds: 0});
      }, 1000);
      this.state.loading = true;

      await this.$axios.post(
          `/users/resend-otp-code?email=${this.$cookies.get("username")}`
      );
      // const text = {
      //     email: 'ایمیل',
      //     sms: 'شماره'
      // }
      this.$error(
          "کد جدید به " + this.otp.email + " شما ارسال شد.",
          "",
          "success"
      );
      this.$cookies.set("username", this.state.userInfo.email);
      if (this.countDown === 0) {
        this.countDown = 120;
        this.countDownTimer();
      }
    },
    onChange(e) {
      this.otp.code = e;
    },
    async submit() {
      this.disablebtn = false;
      if (this.countDown !== 0) {
        this.state.loading = true;
        const res = await this.$axios.post("/users/check-otp-code", this.otp);
        if (res.token) {
          await this.$setToken(res.token);
          await this.$router.push("/profile/account");
        }
      }
    },
    // reset() {
    //     this.otp.code = ''
    //     for (let i = 0; i < this.num; i++) {
    //         this.$refs['code-input'].values[i] = ''
    //         this.$refs['code-input'].$refs['input_' + i][0].value = ''
    //     }
    //     this.$refs['code-input'].$refs['input_0'][0].focus()
    // }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    this.countDownTimer();
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
    if (!this.otp.email) {
      this.$router.push("/login");
    }
    if (this.state.towStepAuthenticationType !== "GOOGLE_AUTHENTICATOR") {
      this.now = DateTime.local().set({milliseconds: 0});
      this.end = DateTime.local()
          .set({milliseconds: 0})
          .plus({seconds: 120});

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({milliseconds: 0});
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.email-verification {
  display: flex;
  justify-content: center;
}
</style>
