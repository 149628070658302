<template>
  <div class="profile">
    <sidebar/>
    <transition
    
     enter-active-class="animate__animated animate__fadeIn"
 leave-active-class = "animate__animated animate__fadeOut"
    >

      <router-view class="content"/>
    </transition>
  </div>
</template>

<script>
import Sidebar from "@/components/profile/sidebar";

export default {
  name: "profile",
  components: {Sidebar},
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  gap: 10px;

  background: #0a1128;

  .content {
    width: 100%;
    padding: 10px;
  }
}
</style>
