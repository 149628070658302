<template>
  <div class="home">
    <banner class="element"/>
    <!-- <dummy /> -->
    <crypto-table class="element"/>
    <Carousel class="element"/>
    <steps class="element"/>
    <Stats class="element"/>
    <FAQ class="element"/>
    <Footer/>

  </div>
</template>

<script>
import Footer from "../sharing-components/footer";
import Banner from "./banner.vue";
import FAQ from "./FAQ.vue";
import Steps from "./Steps.vue";
import Stats from "@/components/home-page/Stats";
import Carousel from "@/components/home-page/Carousel";
import CryptoTable from "@/components/home-page/CryptoTable.vue";
// import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import crypto from "../../library/News";
// import Dummy from "../Dummy";
export default {
  name: "Home",
  components: {
    // Dummy,
    CryptoTable,
    Carousel,
    Stats,
    Banner,
    Steps,
    FAQ,
    Footer,
    // Slide,
    // Hooper,
  },
  data() {
    return {
      coins: crypto,
      hooperSettings: {
        itemsToShow: 3,
        itemsToSlide: 1,
        // centerMode: true,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
// .hooper-slide {
//   width: 40% !important;
// }
.news-container {
  display: flex;
  width: 100%;

  .price-container {
    p {
      font-size: clamp(8px, 1vw, 12px);
    }

    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    column-gap: 15px;
    max-height: 29px;
  }
}

.news {
  z-index: 6;
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  max-height: 29px;
  background: #0f1640;
}

.home {
  overflow: hidden;
  position: relative;

  .element {
    margin-top: 120px;
  }

  .element:first-child {
    margin-top: 0px;
  }

  .element:nth-child(6) {
    margin-bottom: 120px;
  }

  .chat-icon {
    position: fixed;
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 1210px) {
  //.news{
  //  display: none;
  //}
}

@media screen and (max-width: 768px) {
  .home {
    .element {
      margin: 30px 0px;
    }

    .element:nth-child(6) {
      margin-bottom: 30px;
    }
  }
}
</style>
