<template>
  <div class="wallet">
    <div class="first-cont">
      <div class="first-column">
        <CoinsWallet
            @diposit="walletDipositModals"
            @withdrawl="walletWithdrawlModal"
            @passRole="setRole"
            @passToman="setToman"

        />
      </div>

    </div>
    <div class="second-cont">
      <Dashboard
          :role="role"
          :cash="tomancash"
          @diposit="walletDipositRial"
          @withdrawl="walletWithdrawlRial"
      />
    </div>

    <SuccessModal v-if="success" :msgType="msg" @closeModal="closeSuccess"/>
    <RialDipositModal v-if="rialdiposit" @closeModal="closeDipositModal"/>
    <RialwithdrawlModal v-if="rialwithdrawl" @closeModal="closeWidthdrawlModal"/>

  </div>
</template>

<script>
import CoinsWallet from "./CoinsWallet.vue";
import Dashboard from "./Dashboard.vue";
import RialDipositModal from "@/components/profile/wallet/RialDipositModal";
import RialwithdrawlModal from "@/components/profile/wallet/RialwithdrawlModal";
import SuccessModal from "@/components/profile/wallet/SuccessModal";
// import CreateWallet from "./CreateWallet.vue";

export default {

  data() {
    return {
      diposit: false,
      withdrawl: false,
      success: false,
      rialdiposit: false,
      rialwithdrawl: false,
      msg: {
        wit: false,
        dipo: false,
      },
      role: {},
      tomancash: ''
    };
  },
  name: "Wallet",
  components: {
    CoinsWallet,
    SuccessModal,
    // CreateWallet,
    RialwithdrawlModal,
    RialDipositModal,
    Dashboard,

  },
  methods: {


    setRole(value) {
      this.role = value
    },
    setToman(value) {
      this.tomancash = value
    },
    //function that came from coinswallet
    walletDipositModals() {
      this.diposit = true;
      this.withdrawl = false;

      // this.success = value.success
    },
    walletDipositRial() {
      this.rialdiposit = true
      this.rialwithdrawl = false
    },
    walletWithdrawlRial() {
      this.rialwithdrawl = true
      this.rialdiposit = false
    },
    walletWithdrawlModal() {
      this.withdrawl = true;
      this.diposit = false;
    },
    //the function that closes the withdral
    closeWidthdrawlModal() {
      this.msg.wit = true;
      this.msg.dipo = false;
      this.rialwithdrawl = false
      this.withdrawl = false;
      // this.success = true;
    },

    //the function that open dipo

    //the fucntion that close diposit
    closeDipositModal() {
      //the diposit modal
      this.diposit = false;
      this.rialdiposit = false
      //the message
      // this.success = true;
      //turning icon to green and variz
      this.msg.dipo = true;
      //turning icon to red and bardasht
      this.msg.wit = false;
    },
    //function that closes success message
    closeSuccess() {
      this.msg.wit = false;
      this.msg.dipo = false;
      this.success = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  // max-width: 1440px;
  width: 100%;
  display: flex;
  // justify-content: center;
  gap: 8px;
}

.first-cont {
  // max-width: 1000px;
  display: flex;
  gap: 8px;
  overflow: scroll;
  max-height: 780px;
  max-width: 997px;


}

.second-cont {
  display: flex;
  flex-grow: 1;
}

@media (max-width: 1423px) {

  .first-column {
    max-width: 328px !important;
  }

}


@media (max-width: 1085px) {
  .first-cont {
    flex-wrap: wrap !important;
    max-width: 328px;
  }
  @media(max-width: 629px) {
    .wallet {
      flex-wrap: wrap;
    }
    .first-cont {
      order: 2;
      width: 100%;
      max-width: none;

      .first-column, .second-column {
        max-width: unset !important;
        width: 100%;
      }
    }

    .second-cont {
      order: 1;
      width: 100%;
    }

  }
}
</style>
