<template>
  <Transition>
    <div class="modal-backdrop" @click.self="$emit('closeModal')">
      <div class="modal">
        <img @click="$emit('closeModal')" class="close-icon" src="@/assets/profile/closeIcon.svg" alt="">
        <span class="modal-title">
                    شناسایی دو عاملی
                </span>
        <div class="input-container">
                    <span class="describe">
                    برای فعالسازی شناسایی دوعاملی،ابتدا برنامه‌ی Google Authenticator را دانلود نموده و سپس QR کد زیر را توسط آن اسکن نمایید.
                    </span>
          <img
              width="180px"
              :src="'data:image/png;base64,'+qrCode"
              alt="">
          <span class="describe">
                    پس از اسکن بارکد، مورد مربوط به بی بیتکس در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.
                    </span>
          <CodeInput :loading="false" v-on:complete="onComplete" @change="onChange"
                     ref="code-input"/>
        </div>
        <button :disabled=disablebtn @click="submit"
                :class="{'deActive-btn' :this.disablebtn , 'active-btn' : !this.disablebtn}">
          ثبت
        </button>
      </div>
    </div>
  </Transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {Copy, reset, paste} from "@/library/reuseableFunction";

export default {
  name: 'WithGoogle',
  components: {
    CodeInput
  },
  props: ['twoStep', 'qrCode', 'qrLink', 'mobile'],
  data() {
    return {
      close: false,
      disablebtn: true,
      amount: '',
      twostepcode: '',
      walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
      smscode: [],
      error: '',
      num: 6,
      otp: {
        code: '',
      },
      timeOut: '',
      countDown: 120,

    }
  },
  methods: {
    closeModale() {
      this.$emit('closeModal', this.close)
    },
    onComplete() {
      this.disablebtn = false
      this.submit()
    },
    //   text() {
    //      
    //   }
    // countDownTimer() {
    //             if (this.countDown > 0) {
    //                 this.timeOut = setTimeout(() => {
    //                     this.countDown--
    //                     this.countDownTimer()
    //                 }, 1000)
    //             } else {
    //                 this.$error('زمان شما به اتمام رسید', '')
    //             }
    //         },
    // async send() {
    //     this.reset()
    //     this.state.loading = true
    //     const a = {
    //         EMAIL: 'ایمیل',
    //         SMS: 'شماره'
    //     }
    //     const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
    //     if (res.message === 'Verification Code Sent') {
    //         this.$error('', 'کد به ' + a['GOOGLE_AUTHENTICATOR'] + ' شما ارسال شد', 'success')
    //         if (this.countDown === 0)
    //             this.countDown = 120
    //         this.countDownTimer()
    //     }
    // },
    // close() {
    //     this.$emit('close');
    // },
    onChange(e) {
      this.otp.code = e
    },
    async submit() {
      const a = {
        'EMAIL': 'ایمیل',
        'SMS': 'پیامک',
        'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
      }
      if (this.otp.code.toString().length === 6) {
        if (this.$route.name !== 'Wallet') {
          this.state.loading = true
          const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.otp.code}`)
          if (res) {
            this.reset()
            this.$emit('close')
            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + a['GOOGLE_AUTHENTICATOR'] + ' تغییر یافت.')
          }
        } else {
          this.$emit('submit', this.otp.code)
        }
      }
    },
    Copy, paste, reset

  },
  mounted() {
    this.$refs['code-input'].$refs['input_0'][0].focus()
    // if (this.twoStep !== 'GOOGLE_AUTHENTICATOR') this.countDownTimer()
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
    document.body.style.overflow = 'auto'
  },


}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  flex-grow: 1;
  max-width: 384px;
  min-height: 501px;
  background: #001E3D;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 16px;
}

.modal-title {
  font-weight: bold;
  font-size: 24px;
  color: #FEFEFE;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
}

.describe {
  font-size: 12px;
  color: #FEFEFE;
}

.deActive-btn, .active-btn {
  border-radius: 6px;
  border: none;
  color: #FCFCFC;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 44px;
}

.active-btn {
  background: #19196f;
}

.deActive-btn {
  background: #19196f;
  opacity: 0.7;
}
</style>
