<template>
  <div class="buy-coin">
    <place-order
        v-if="placingOrder"
        @close="placingOrder = false"
        @accept="sendData"
        :unitPrice="data.unitPrice"
        :totalPrice="data.totalPrice"
        :amount="data.amount"
        :coin="data.destinationCoin"
        :type="this.buy"
    />
    <div class="buttons">
      <button @click.prevent="buy=true;getPrice();tomanTetherWallet()" :class="{ 'active-buy': buy }">
        خرید
      </button>
      <button @click.prevent="buy=false;getPrice();tomanTetherWallet()" :class="{ 'active-sell': !buy }">
        فروش
      </button>
    </div>
    <div class="toman-input">
      <div class="amount">
        <button @click="activeTeter" class="teter-btn">
          <img :src="logoTeter" alt="" width="30px"/>
          <span class="price"> {{ selectTeter }} </span>

          <svg
              width="12"
              height="24"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M2.11997 0.790001L5.99997 4.67L9.87997 0.790001C10.27 0.400001 10.9 0.400001 11.29 0.790001C11.68 1.18 11.68 1.81 11.29 2.2L6.69997 6.79C6.30997 7.18 5.67997 7.18 5.28997 6.79L0.699971 2.2C0.309971 1.81 0.309971 1.18 0.699971 0.790001C1.08997 0.410001 1.72997 0.400001 2.11997 0.790001Z"
                fill="#279BC0"
            />
          </svg>
        </button>
        <div class="drop-teter" v-if="dropdownTeter">

          <div
              class="drop-row"
              v-for="(item, index) in dropTeter"
              :key="index"
              @click.prevent="selectTeterItem(index)"
          >
            <img :src="item.image" alt="" width="40px"/>
            <span> {{ item.name }}</span>
          </div>
        </div>
        <p class="seperator">|</p>
      </div>

      <input
          type="text"
          class="toman"
          :placeholder="'مقدار' + ' ' + selectTeter"
          @input="inputsFun('totalPrice')"
          v-model="data.totalPrice"
          id="toman"
      />
    </div>
    <label>{{ this.buy ? 'پرداخت میکنید' : 'دریافت میکنید' }}</label>
    <label v-if="state.token && this.state.userInfo.authorized">
<template v-if="buy">
  موجودی :
  {{ BuywalletBalance>=0?$toLocal(BuywalletBalance, $decimal['TOMAN']): BuywalletBalance }}
  {{ type == 'TOMAN' ? 'تومان' : 'تتر' }}
</template>
      <template v-if="!buy">
        موجودی :
        {{ SellwalletBalance>=0?$toLocal(SellwalletBalance, $decimal[coin]):SellwalletBalance }}
{{$coins[coin].persianName}}
      </template>
    </label>
    <div class="transfer-logo" :class="{'mirror' : !buy}">
      <img
          @click="buy = !buy"
          src="../../assets/public/banner-transfer-logo.png"
          alt=""
          width="63"
          height="57"
      />


    </div>
    <div class="crypto-changes">
      <div class="changes">
        <button @click="activeDropdown" class="dropdown-btn">
          <img :src="'/coins/' + logo + '.png'" alt="" width="34"/>
          <span class="price">{{ select }}</span>

          <svg
              width="12"
              height="24"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M2.11997 0.790001L5.99997 4.67L9.87997 0.790001C10.27 0.400001 10.9 0.400001 11.29 0.790001C11.68 1.18 11.68 1.81 11.29 2.2L6.69997 6.79C6.30997 7.18 5.67997 7.18 5.28997 6.79L0.699971 2.2C0.309971 1.81 0.309971 1.18 0.699971 0.790001C1.08997 0.410001 1.72997 0.400001 2.11997 0.790001Z"
                fill="#279BC0"
            />
          </svg>
          <span class="seperator"> | </span>
        </button>
        <div class="dropdown" v-if="dropdown">
          <div
              class="drop-row"
              v-for="(item, index) in (coins.filter(item => item.name !== 'TOMAN'))"
              :key="index"
              @click.prevent="selectItem(index)"
          >
            <img :src="'/coins/' + item.code + '.png'" alt="" width="40px"/>
            <span>
              {{ item.persianName }}
            </span>
          </div>
        </div>
      </div>
      <div class="crypto-amount">
        <input :placeholder="!buy ? `مقدار (${select})` :`مقدار (${select})`" @input="inputsFun('amount')"
               v-model="data.amount"/>
      </div>
    </div>
    <label>{{ this.buy ? 'دریافت میکنید' : 'پرداخت میکنید' }}</label>
    <div class="bit-price">
      <div class="texts">
        <p>۱ {{ select }}</p>
        <p>{{ type=='TOMAN'?$toLocal(data.unitPrice,$decimal['TOMAN']):$toLocal(data.unitPrice, $decimal['TETHER']) }} {{type=='TOMAN'?'تومان':'تتر'}}</p>
      </div>
    </div>

    <div v-if="state.token && this.state.userInfo.authorized">
      <button class="buy-btn" v-if="buy" @click.prevent="placingOrder = true" :disabled="!!buyReadOnly">
        {{ buyReadOnly ? buyReadOnly : 'خرید رمز ارز' }}
      </button>
      <button v-else class="sell-btn" @click.prevent="placingOrder = true" :disabled="!!sellReadOnly">
        {{ sellReadOnly ? sellReadOnly : 'فروش رمز ارز' }}
      </button>
    </div>
    <button class="buy-btn" v-if="!state.token" @click.prevent="$router.push('/login')">ورود</button>


    <ex-modal
        v-if="success"
        :unitprice="data.unitPrice"
        :totalprice="data.totalPrice"
        :ordertype="data.orderType"
        :amount="data.amount"
        @closeModal="success = false"
    />
  </div>
</template>

<script>
import ExModal from "../profile/otc/exModal.vue";
import PlaceOrder from "../profile/otc/PlaceOrder.vue";
import {Loop} from "@/library/reuseableFunction"

export default {
  components: {PlaceOrder, ExModal},
  data() {
    return {
      success: false,
      sell: false,
      placingOrder: false,
      dropdown: false,
      dropdownTeter: false,
      type: "TOMAN",
      select: "",
      logo: "",
      selectTeter: "تومان",
      logoTeter: "",
      price: "1,435,972,193",
      buy: true,
      placed: false,
      temptData: {},
      isTyping: false,
      disable: true,
      loop: undefined,
      sellAmount: "",
      coin: "BITCOIN",
      credit: "",
      walletBalance: {},
      loading: false,
      prices: "",
      beebitexWallet: true,
      netDrop: false,
      selected_net: "",
      nets: [],
      coins: [],
      txIdIsNeeded: false,
      totalPrice: "",
      exModal: false,
      walletType: '',
      BuywalletBalance: '',
      SellwalletBalance: '',
      minAmountExchange: 0,
      maxAmountExchange: 1000000,
      data: {
        marketType: "TRON_TOMAN",
        amount: null,
        sourceCoin: "TOMAN",
        destinationCoin: "TRON",
        orderType: "EXCHANGE_BUY",
        // tokenType: "",
        // destinationWallet: "",
        unitPrice: null,
        totalPrice: null,
        dollar: null,
        wage: 0.3,
      },
      placeholderCrypto: "دریافت میکند",
      placeholderToman: "می پردازد",
      amount: "",
      onLoad:'',
      drop: false,
      modal: false,
      alive: true,
      toman: true,
      wallet: [{}],
      fromAmount: true,
      calculating: false,


      //teter toman dropdown
      dropTeter: [
        {
          name: "تومان",
          image: require("../../assets/coins/TOMAN.png"),
          show: true,
          market: "TOMAN",
        },
        {
          name: "تتر",
          image: require("../../assets/coins/TETHER.png"),
          show: false,
          market: "TETHER",
        },
      ],
    };
  },
  computed: {
    sellReadOnly() {

      let error = "";

      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
        // this.disableSell = true
      }
      else if (this.SellwalletBalance < this.$S2N(this.data.totalPrice)) {
        error = "موجودی کافی نیست"
      }




      else if (this.data.totalPrice !== 0 && this.data.amount == 0) {
        error = "حداقل مقدار رعایت نشده است"
      } else if (this.$S2N(this.data.amount) < this.minAmountExchange) {
        error = "حداقل مقدار رعایت نشده";
        // this.disableSell = true
      } else if (
          (this.buy &&
              this.$S2N(this.data.totalPrice) > this.SellwalletBalance) ||
          (!this.buy && this.$S2N(this.data.amount) > this.SellwalletBalance)
      ) {
        error = "مقدار بیش از موجودی شماست";
        // this.sellAmount = 'مقدار بیش از موجودی شماست'
        // this.disableSell = true
      } else if (this.$S2N(this.data.amount) > this.maxAmountExchange) {
        error = "حداکثر مقدار رعایت نشده";
        // this.sellAmount = 'حداکثر مقدار رعایت نشده'
        // this.disableSell = true
      } else if (this.calculating) {
        error = "لطفا صبر کنید ...";
      }
      return error;
      //  else {
      //     this.disableSell = false
      //     this.sellAmount = ''
      // }
    },
    buyReadOnly() {

      let error = "";
      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
      }
      else if (this.BuywalletBalance < this.$S2N(this.data.totalPrice)) {
        error = "موجودی کافی نیست"
      }


      else if (this.$A2N(this.data.amount) < (this.$A2N(this.minAmountExchange))) {
        error = "حداقل مقدار رعایت نشده";
      }

      // else if (
      //     this.$A2N(this.data.totalPrice) > this.BuywalletBalance
      // )
      //
      // {
      //   error = "مقدار بیش از موجودی شماست";
      // }


      else if (this.$S2N(this.data.amount) > this.$A2N(this.maxAmountExchange)) {
        error = "حداکثر مقدار رعایت نشده";
      }


      else if (!this.beebitexWallet) {
        if (this.data.destinationWallet == "") {
          error = " آدرس کیف پول اجباری است ";
        } else if (this.data.tokenType == "") {
          error = "انخاب شبگه اجباری است";
        }
      } else if (this.data.totalPrice !== 0 && this.data.amount == 0) {
        error = "حداقل مقدار رعایت نشده است"
      }


      else if (this.calculating) {
        error = "لطفا صبر کنید ...";
      }

      return error;
    },
  },

watch:{
toman(){
this.getCoins()

}

},


  methods: {
    /* axios methods stands in here */
    async sendData() {
      this.placingOrder = false
      this.temptData = JSON.parse(JSON.stringify(this.data));
      this.temptData.amount = this.$S2N(this.temptData.amount);
      this.temptData.marketType = `${this.coin}_${this.type}`;
      delete this.temptData.destinationCoin
      this.temptData.destinationCoin = this.coin;
      this.data.destinationCoin = this.coin;
      this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice);
      this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice);
      this.exModal = false;
      if (this.buy) {
        this.state.loading = true;
        await this.$axios.post("/exchange/buy", this.temptData);
        this.success = true;
      } else {
        delete this.temptData.destinationWallet;
        delete this.temptData.destinationCoin
        delete this.temptData.tokenType;
        this.temptData.orderType = "EXCHANGE_SELL";
        this.temptData.destinationCoin = this.coin;
        this.data.destinationCoin = this.coin;
        this.data.orderType = "EXCHANGE_SELL";
        this.state.loading = true;
        await this.$axios.post("/exchange/sell", this.temptData);
        this.success = true;
        this.getWallets();
        this.tomanTetherWallet();
        this.state.loading = false;
      }
    },
    async getPrice() {
      const res = await this.$axios.get(
          "coins/exchange-price/" + (this.type === "TOMAN" ? 'usd' : 'usdt') + "?includeWeeklyChange=false&destCoinSymbol=" +
          this.coin
      );
      this.prices = res;

      this.data.unitPrice = this.prices.find(
          (a) => a.destCoinSymbol == this.coin
      )[this.buy ? "buyPrice" : "sellPrice"];
      if (this.fromAmount) {
        this.calcTotalPrice();
      } else {
        this.calcAmount();
      }
      this.tomanTetherWallet()
    },
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.coins = res.content.filter((name) => name.coin !== "TOMAN");
      if(this.type ==='TETHER'){
        this.coins = res.content.filter((name)=>name.coin !=='TETHER')
      }
      this.minAmountExchange = this.coins.find(a => a.coin == this.coin).minAmountExchange
      this.maxAmountExchange = this.coins.find(a => a.coin == this.coin).maxAmountExchange
      // this.onLoad = res.content[0].persianName
      // this.select = res.content[0].persianName;
      console.log(res.content[0].code)
      this.onLoad = res.content[0].code
      // this.logo = res.content[0].code;
      this.state.loading = false;
      // console.log(res);
    },


    async tomanTetherWallet() {
      // this.loading = true
      if (this.state.token && this.state.userInfo.authorized) {

if(this.buy){
  const [res2] = await this.$http.get(
      `/wallets/customer-addresses?walletType=EXCHANGE&coins=${this.type}`
  );
  // console.table(res2)
  if (res2) {
const {TOMAN} = res2

    console.log(TOMAN);
    this.BuywalletBalance = this.type == 'TOMAN' ? res2["TOMAN"].credit : res2["TETHER"].credit;
  }
}

else{

 if(!this.buy){
   const [res]= await this.$http.get(`/wallets/customer-addresses?walletType=EXCHANGE&coins=${this.coin}`)
   if(res){

     this.SellwalletBalance = res[this.coin].totalBalance
   }
 }







}



      }

      // this.loading = false
    },


    /*---------------------------------------------------------------------------------------------*/

//calculations stands here


    checkModal() {
      if (this.buy) {
        this.data.marketType = this.coin + '_' + (this.toman ? 'TOMAN' : 'TETHER')
        this.data.destinationCoin = this.coin
        this.data.orderType = 'EXCHANGE_BUY'
        this.exModal = true
      } else {
        this.data.marketType = this.coin + '_' + (this.toman ? 'TOMAN' : 'TETHER')
        this.data.destinationCoin = this.coin
        this.data.orderType = 'EXCHANGE_SELL'
        this.exModal = true
      }
    },
    calcTotalPrice() {
      this.data.totalPrice = this.$toLocal(this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice), this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
    },
    calcAmount() {
      this.data.amount = this.$toLocal(this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice), this.$decimal[this.coin])
    },
    async unitFun() {
      this.data.totalPrice = ''
      if (this.loop) {
        this.loop.stop()
      }
      this.toman = !this.toman;
      // $emit('changeUnit',toman);
      // calcTotalPrice();calcAmount();
      await this.tomanTetherWallet()
      this.beforeLoop()
    },
    async inputsFun(e) {
      this.calculating = true
      if (e === 'amount') {
        this.data.amount = this.$toLocal(this.data.amount, this.$decimal[this.coin])
      } else {
        this.data.totalPrice = this.$toLocal(this.data.totalPrice, this.$decimal['TOMAN'])
      }


      this.fromAmount = e === 'amount'
      if (this.loop) {
        this.loop.stop()
      }
      const temp = this.data[e]
      await this.$sleep(500)
      if (temp === this.data[e]) {
        this.beforeLoop()
      }
      this.calculating = false
    },

    beforeLoop() {
      this.calculating = true
      if (this.loop) {
        this.loop.stop()
      }
      this.loading = true
      if (this.state.loop) {
        this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
        this.loop.start()
      } else {
        this.getPrice()
      }
    },

    async totalPriceFun() {
      // this.calcAmount();
      // this.sellReadOnly()
      // this.data.totalOfAll = this.$toLocal(this.data.totalPrice * this.data.amount, this.$decimal[this.toman ? 'TOMAN' : 'TETHER'])
      this.calculating = true
      let total = this.data.amount * this.data.totalPrice * this.data.dollar
      this.data.totalPrice = this.$toLocal(total, 1)


    },

    /*---------------------------------------------------------------------------------------------*/

//dropdowns & another methods stands here
    selectItem(index) {
if(this.toman){
        this.select = this.coins[index].persianName;
      this.coin = this.coins[index].coin;
      this.logo = this.coins[index].code;
}

if(!this.toman){
        this.select = ''
      this.coin = ''
      this.logo = ''
}
      this.getCoins()
      this.getPrice();
      this.tomanTetherWallet();
      this.dropdown = false;
    },
    selectTeterItem(index) {
      this.selectTeter = this.dropTeter[index].name;
      this.logoTeter = this.dropTeter[index].image;
      this.type = this.dropTeter[index].market;
      
      this.getPrice();
      this.getCoins();
      this.tomanTetherWallet();
      this.dropdownTeter = false;
    },
    activeDropdown() {
      this.dropdown = !this.dropdown;
      //function that close dropdown from window
      window.addEventListener("click", (event) => {
        if (!event.target.closest(".dropdown-btn,.dropdown")) {
          this.dropdown = false;
        }
      });
    },
    activeTeter() {
      this.dropdownTeter = !this.dropdownTeter;

      //function that close dropdown from window
      window.addEventListener("click", (event) => {
        if (!event.target.closest(".teter-btn,.dropdown")) {
          this.dropdownTeter = false;
        }
      });
    },


  },
  async mounted() {
    await this.getCoins();
    await this.getPrice();
    await this.tomanTetherWallet();


      this.select= this.coins[0].persianName;
    this.logo=this.coins[0].code;
 

    this.selectTeter = this.dropTeter[0].name;
    this.logoTeter = this.dropTeter[0].image;
    this.type = this.dropTeter[0].market;
    this.market = "BITCOIN";

  },
};
</script>

<style lang="scss" scoped>
@keyframes rotation {
  0% {

  }

  100% {

  }
}

.mirror {
  transform: rotateZ(180deg);
}


.transfer-logo {
  transition: transform 1s ease;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;


}


.amount {
  position: relative;
}

.drop-teter {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  top: 50px;
  z-index: 12;
  width: 150px;
  background: #141b31;
  border-radius: 4px;
  padding: 10px;
  column-gap: 20px;

  .drop-row {
    display: flex;
    row-gap: 10px;
    align-items: center;
    column-gap: 10px;
    margin-top: 15px;
    font-size: clamp(12px, 1vw, 16px);
    color: white;
    width: 100%;

    > img {
      width: 25px;
      height: 25px;
    }
  }
}

.teter-btn {
  .seperator {
    font-size: 18px;
    color: #9c9fc0;
  }

  .price {
    font-size: clamp(12px, 0.5vw, 16px);
  }

  > svg {
    margin-right: 18px;
  }

  span {
    padding: 0px 8px;
  }

  padding: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}

.dropdown-btn {
  .seperator {
    font-size: 18px;
    color: #9c9fc0;
  }

  .price {
    font-size: clamp(10px, 0.5vw, 16px);
  }

  > svg {
    margin-right: 18px;
  }

  span {
    padding: 0px 8px;
  }

  padding: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
}

.dropdown {
  cursor: pointer;
  height: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 200px;
  background: #141b31;
  border-radius: 4px;
  padding: 10px;
  column-gap: 20px;
  top: 50px;
  z-index: 10;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .drop-row {
    display: flex;
    row-gap: 10px;
    align-items: center;
    column-gap: 10px;
    margin-top: 15px;
    font-size: clamp(12px, 1vw, 16px);
    color: white;
    width: 100%;

    > img {
      width: 25px;
      height: 25px;
    }
  }
}

.dropdown::-webkit-scrollbar {
  display: none;
}

.buy-coin {
  height: 550px;
  width: 432px;

  > label {
    margin-top: 12px;
    font-size: clamp(10px, 2vw, 14px);
    color: #9c9fc0;
  }

  display: flex;
  flex-direction: column;
  background: #141b31;
  border-radius: 12px;
  padding: 16px;

  //buttons div
  .buttons {
    transition: all 1s ease;
    width: 100%;
    align-self: center;
    display: flex;
    overflow: hidden;
    background: #9c9fc029;
    min-height: 44px;
    border-radius: 8px;
    font-size: clamp(12px, 1vw, 16px);
    max-width: 300px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: 0;
      background: transparent;
      flex-grow: 1;

      .sell {
        position: absolute;
      }
    }

    .active-buy {
      border-radius: 8px;
      background: #26c22c;
    }

    .active-sell {
      border-radius: 8px;
      background: #e53756;
    }
  }

  //toman tether inputs
  .toman-input {
    margin-top: 40px;
    max-height: 44px;
    border-radius: 8px;
    border: 1px solid #279bc0;
    display: flex;
    justify-content: space-between;

    .amount {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      min-width: 132px;

      > p {
        font-size: clamp(12px, 1vw, 16px);
        margin-right: 8px;
      }

      .seperator {
        font-size: 18px;
        color: #9c9fc0;
      }
    }

    > input {
      width: 100%;
      background: none;
      border: none;
      text-align: left;
      color: #9c9fc0;
    }
  }


  //dropdowns
  .crypto-changes {
    margin-top: 40px;
    max-height: 44px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #279bc0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .changes {
      position: relative;
    }

    .crypto-amount {
      justify-content: flex-end;
      display: flex;
      height: 100%;

      > input {
        border: 0;
        font-family: iranwebsans;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        text-align: left;
        color: #9c9fc0;
      }
    }
  }

  .bit-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 44px;
    width: 100%;
    border-top: 1px dashed #279bc0;
    height: 103px;

    .texts {
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        color: #9c9fc0;
        display: inline;
      }
    }
  }

  //buttons
  .buy-btn,
  .sell-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    min-height: 44px;
    border: 0;
    border-radius: 8px;
    margin-bottom: 8px;
  }


  .buy-btn {
    background: #26c22c;
  }

  .sell-btn {
    background: #e53756;
  }
}

@media screen and (max-width: 900px) {
  .buy-coin {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .dropdown-btn {
    min-width: 120px;

    img {
      display: none;
    }

    svg {
      margin: 0;
    }
  }
}
</style>
