<template>
  <div class="activeorders">
    <div class="order-container">
      <h3 class="text-center">سفارشات فعال</h3>
      <div class="orders">
        <template v-for="(order, index) in tableContents">

          <div

              class="order"

              :key="index"
          >
            <cancel-order v-if='cancelModal==true' @close="cancelModal=false" @remove="removeorder"/>
            <div class="order-time-store">
              <div class="coin-type">
                <img v-if="order.orderType.includes('BUY')" :src="'/coins/'+ order.destinationCoin + '.png'"
                     :alt=" order.destinationCoin" width="24px">
                <img v-else :src="'/coins/'+ order.sourceCoin + '.png'" :alt=" order.sourceCoin" width="24px">
                <p class="coin-color" v-if="order.orderType.includes('BUY')">{{ $coinLabel[order.destinationCoin] }}</p>
                <p class="coin-color" v-else>{{ $coinLabel[order.sourceCoin] }}</p>
                <p>-</p>
                <p :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                  {{ $orderType[order.orderType] }}</p>

              </div>
              <div class="coin-situation">
                <p
                    class="gray"

                >
                  {{ $orderStat[order.orderStatus] }}
                </p>
              </div>
              <div class="time">
                <p class="gray">
                  {{ $G2J(order.createdAtDateTime) }}
                </p>
              </div>
            </div>
            <div class="order-situation-type">
              <!--                            <p class="finish">{{order.dealtype}}</p>-->
            </div>
            <div class="details-table">
              <table>
                <thead>
                <tr>
                  <td class="gray">مقدار</td>
                  <td class="gray">قیمت</td>
                  <td class="remove gray">مجموع</td>
                  <td class="gray">اتمام شده</td>
                  <td></td>
                </tr>
                </thead>
                <tr class="item">
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.amount }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.unitPrice.toLocaleString() }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }"
                      class="remove">{{ $toLocal(order.wholePrice, 1) }}
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }">
                    {{ order.executedPercent.toFixed(1) }} %
                  </td>
                  <td :class="{'finish':order.orderType.includes('BUY') , 'cancel':order.orderType.includes('SELL') }"
                      @click="showModal(order)">
                    <a
                    ><img src="../../../assets/public/bin.png" alt=""
                    /></a>
                  </td>
                </tr>
              </table>
            </div>

          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  tradeFrom,
  tradeTo,
  precision,
} from "@/library/reuseableFunction";
import CancelOrder from './CancelDash.vue';

export default {
  components: {CancelOrder},
  name: "Activeorders",
  data() {
    return {
      cancelModal: false,
      removeid: 0,
      cancel: false,
      tableContents: [],
      loading: false,
      alive: false,
      loopIsAlive: false,
    };
  },
  computed: {
    precision,
    tradeFrom,
    tradeTo
  },
  methods: {


    closemodal() {
      this.modalshow = false
    },
    showModal(e) {
      console.log(e)
      // this.loop.stop()
      this.cancelModal = true
      this.removeid = e.id
    },
    async removeorder() {
      //   this.state.loading = true
      this.cancelModal = false
      const id = this.removeid.toString()
      const res = await this.$axios.delete(`orders/${id}`, {
        header: {
          token: this.state.token
        }
      })
      console.table(res)
      if (res) {
        ++this.state.orderChange
        this.modalshow = false
        this.completedModal = true
        this.$error('سفارش با موفقیت حذف شد', '', 'success')
        if (this.state.loop) {
          this.loop.start()
        } else {
          this.getActiveOrders()
        }
      }
    },
    async getActiveOrders() {

      const res = await this.$axios.get('/orders', {
        params: {
          orderStatus: 'IS_OPEN',
        }
      })

      this.tableContents = res.content || [];
      // this.$emit('update:activeLength', this.tableContents.length)
    },
    async loop() {
      // this.state.loading = true
      this.loading = true

      while (this.alive) {
        this.loopIsAlive = true
        await this.getActiveOrders()
        this.loading = false
        await this.$sleep(5000)
      }
      this.loopIsAlive = false
    }
  },
  async mounted() {

    this.alive = true
    this.loading = true
    if (this.state.loop) {
      this.loop()
    } else {
      await this.getActiveOrders()
      this.loading = false
    }
  },
  beforeDestroy() {
    this.alive = false
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: clamp(9px, 1vw, 12px);
}

.coin-color {
  color: #febc00;
  width: 45%;
  font-size: 12px;
}

.gray {
  color: #9c9fc0;
}

.ispending {
  color: #9c9fc0;
  font-size: 12px;
}

.cancel {
  color: rgba(255, 0, 92, 1);
  font-size: 12px;
}

.finish {
  color: rgba(51, 158, 0, 1);
  font-size: 12px;
}

.item {
  color: rgba(51, 158, 0, 0.5);
}

.activeorders {
  height: 60%;
  flex-grow: 1;
}

.order-container {
  background: #0f1640;
  border-radius: 16px;
  //box-shadow: 0px 0px 16px rgba(0, 119, 204, 0.1);
  // max-width: 384px;
  min-height: 342px;
  max-height: 342px;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  padding: 16px;
}

.orders {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.order {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0077cc49;
}

.order-targetmarket {
  font-family: "Yekan-en" !important;
}

.order-situation-type,
.order-situation-store,
.time,
.coin-type,
.coin-situation,
.order-time-store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 3px;
}

button {
  padding: 0px;
  background: none;
  color: #0077cc;
  opacity: 0.5;
  font-size: clamp(10px, 1.5vw, 14px);
}

.enable {
  opacity: 1;
  cursor: pointer;
}

@media only screen and(max-width: 1150px) {
  .order-container {
    max-width: none;
  }
}

@media only screen and(max-width: 450px) {
  .remove {
    display: none;
  }
  .order-time-store,
  .order-situation-type,
  .order-situation-store {
    flex-direction: column;

    div,
    .order-targetmarket {
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
@media(max-width: 485px) {
  table {
    display: none;
  }
}
</style>