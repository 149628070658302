<template>
  <div class="trade">
    <div class="dropdowns">
      <div class="trades">


        <button class="btn" @click.prevent="tradeDrop = !tradeDrop">
          <span> {{ select }}</span>
          <InlineSvg
              :src="require('../../../assets/profile/icons/dropdown.svg')"
          />
        </button>
        <div class="list" v-if="tradeDrop">
          <div
              v-for="(item, index) in orderType"
              :key="index"
              @click.prevent="selectItem(index)"
          >
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <template v-if="orderType[0].state === true">
        <div class="crypto">
          <button class="btn" @click.prevent="cryptoDrop = !cryptoDrop">
          <span>
            <img :src="'/coins/'+selectP2p+'.png'" width="16" height="16" alt="logo"/>
            {{ $coins[selectP2p].persianName }}
          </span>
            <InlineSvg
                :src="require('../../../assets/profile/icons/dropdown.svg')"
            />
          </button>


          <div class="crypto-list" v-if="cryptoDrop">
            <div
                v-for="(item, index) in p2pCoins"
                :key="index"
                class="list-items"
                @click.prevent="selectP2P(index)"
            >
              <p>
              <span><img :src="'/coins/'+item.destCoinSymbol+'.png'" alt="" width="14" height="14"/></span
              >{{ $coins[item.destCoinSymbol].persianName }}
              </p>
            </div>
          </div>


        </div>
      </template>
      <template v-if="orderType[1].state === true">
        <div class="crypto">
          <button class="btn" @click.prevent="cryptoDrop = !cryptoDrop">
          <span>
            <img :src="'/coins/'+selectExchange+'.png'" width="16" height="16" alt="logo"/>
            {{ $coins[selectExchange].persianName }}
          </span>
            <InlineSvg
                :src="require('../../../assets/profile/icons/dropdown.svg')"
            />
          </button>


          <div class="crypto-list" v-if="cryptoDrop">
            <div
                v-for="(item, index) in ExCoins"
                :key="index"
                class="list-items"
                @click.prevent="selectEx(index)"
            >
              <p>
              <span><img :src="'/coins/'+item.coin+'.png'" alt="" width="14" height="14"/></span
              >{{ $coins[item.coin].persianName }}
              </p>
            </div>
          </div>


        </div>
      </template>
      <template v-if="orderType[2].state === true">
        <div class="crypto">
          <button class="btn" @click.prevent="cryptoDrop = !cryptoDrop">
          <span>
            <img :src="'/coins/'+selectExchange+'.png'" width="16" height="16" alt="logo"/>
            {{ $coins[selectExchange].persianName }}
          </span>
            <InlineSvg
                :src="require('../../../assets/profile/icons/dropdown.svg')"
            />
          </button>


          <div class="crypto-list" v-if="cryptoDrop">
            <div
                v-for="(item, index) in ExCoins"
                :key="index"
                class="list-items"
                @click.prevent="selectEx(index)"
            >
              <p>
              <span><img :src="'/coins/'+item.coin+'.png'" alt="" width="14" height="14"/></span
              >{{ $coins[item.coin].persianName }}
              </p>
            </div>
          </div>


        </div>
      </template>
    </div>

    <advanced-table v-if="orderType[0].state===true" :coin="selectP2p"/>
    <ExchangeTable v-if="orderType[1].state===true" :coin="selectExchange"/>
    <all-table v-if="orderType[2].state===true" :coin="selectExchange"/>


  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import AdvancedTable from './AdvancedTable.vue';
import ExchangeTable from "./ExchangeTable.vue"
import AllTable from "./AllTable.vue"

export default {
  name: "TradeHistory.vue",
  components: {
    InlineSvg,
    AdvancedTable,
    ExchangeTable,
    AllTable

  },
  data() {
    return {
      orderType: [
        {
          type: 'P2P',
          title: 'معاملات حرفه ای',
          state: true
        },
        {
          type: 'EXCHANGE',
          title: 'معاملات سریع',
          state: false
        },
        {
          title: 'همه',
          state: false
        }
      ],
      allContents: [],
      selectP2p: '',
      selectExchange: '',
      selectAll: '',
      allCoins: [],
      tradeDrop: false,
      cryptoDrop: false,
      select: "",
      selectCrypto: "",
      logo: "",
      p2pCoins: [],
      ExCoins: [],
      p2pContents: [],
      lastPage: 1,
      page: 0,
      loading: false,
      tradeItems: [
        {
          title: "معاملات سریع",
          show: true,
        },
        {
          title: "معاملات حرفه ای",
          show: false,
        },
        {
          title: " همه",
          show: false,
        },
      ],
      //table of proffisional trades


      //table of exchange trades


      allTable: [
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "حد-خرید",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/xmark.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },
        {
          type: "فروش",
          crypto: "بیت‌کوین- تومان ",
          priceBase: "۳۴۵,۶۶۶,۸۶۴",
          amount: "۰.۱۲ BTC",
          totalPrice: "۳۴۵,۶۶۶,۸۶۴",
          transaction: "-",
          fee: "کارمزد",
          time: "زمان ",
          status: require("../../../assets/profile/icons/tick.svg"),
        },

      ],


    };
  },

  methods: {
    //this function is for the advanced trade coins
    async getCoins() {
      this.state.loading = false
      const link = '/coins/price/usd'
      let [res,] = await this.$http.get(link, {
        params: {
          include24hrChange: true,
          includeWeeklyChange: false
        }

      })
      this.p2pCoins = res

    },
    //this function is for the exchange trade coins
    async getExCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.ExCoins = res.content.filter(name => name.coin !== 'TOMAN')
      this.state.loading = false

    },
    //this function is for select type of history
    selectItem(index) {
      this.select = this.orderType[index].title;
      this.tradeDrop = false;
      for (let index = 0; index < this.orderType.length; index++) {
        this.orderType[index].state = false;
      }
      this.orderType[index].state = true;
    },
//this function is for select p2p coins
    selectP2P(index) {
      this.selectP2p = this.p2pCoins[index].destCoinSymbol
      console.log(this.selectP2p)
      this.logo = this.p2pCoins[index].coin;
      this.cryptoDrop = false;
      //   for (let index = 0; index < this.cryptoItems.length; index++) {
      //     this.cryptoItems[index].show = false;
      //   }
      //   this.cryptoItems[index].show = true;
      // },
    },
// this function is for select exchange coins
    selectEx(index) {
      this.selectExchange = this.ExCoins[index].coin
      console.log(this.selectExchange)
      this.logo = this.ExCoins[index].coin;
      this.cryptoDrop = false;
    },


  },
  async mounted() {
    await this.getCoins()
    await this.getExCoins()
    console.log(this.p2pContents)
    //when Page reloads this datas will be inject to our buttons
    this.allCoins = [...this.p2pCoins, ...this.ExCoins]
    this.select = this.orderType[0].title;
    this.selectP2p = this.p2pCoins[0].destCoinSymbol
    this.selectExchange = this.ExCoins[0].coin
    this.logo = ''


    // this.coins = this.$coins
    // console.log(this.coins)

  },
}
</script>

<style lang="scss" scoped>
.trade {
  height: 564px;
  max-height: 564px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: #0f1640;
  border-radius: 16px;
  padding: 24px;
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  width: 100%;


  justify-content: space-between;

  .trades {
    position: relative;

    button {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #279bc0;
      border-radius: 4px;
      min-height: 36px;
      min-width: 174px;
    }

    .list {
      overflow: scroll;
      max-height: 200px;
      position: absolute;
      margin-top: 2px;
      background: rgb(10, 17, 40);
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      z-index: 30;
    }
  }

  .crypto {
    position: relative;
    z-index: 20;

    button {
      padding: 6px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      column-gap: 26px;
      border: 1px solid #279bc0;
      border-radius: 4px;
      min-height: 36px;
      min-width: 140px;

      span {
        display: flex;
        column-gap: 4px;
        align-items: center;
      }
    }

    .crypto-list {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 2px;
      background: rgb(10, 17, 40);
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      overflow: scroll;
      max-height: 150px;

      .list-items {
        padding: 5px;

        span {
          display: flex;

          img {
            align-self: center;
          }
        }

        > p {
          font-size: clamp(12px, 1vw, 16px);
          display: flex;
          align-items: center;
          min-height: 20px;
          gap: 8px;

        }

      }
    }
  }
}

.p2p-table,
.exchange-table {
  width: 100%;


  tbody {

    display: block;
    height: 100%;
    max-height: 350px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

  }

  tbody::-webkit-scrollbar {
    display: none;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  thead {
    width: calc(100% - 1em) /* scrollbar is average 1em/16px width, remove it from thead width */
  }

  table {
    width: 400px;
    height: 524px;
  }

  .th {
    color: #febc00;
    font-size: clamp(12px, 1vw, 16px);
    min-width: 50px;
  }

  .table-row {
    text-align: center;
    padding: 20px;

    .text {
      font-size: clamp(12px, 1vw, 16px);
    }

    .tablet {
    }

    .phone {
    }
  }
}

.p2p-table {
  .table-row {
    .green {
      color: #26c22c;
    }

    .red {
      color: #e53756;
    }
  }
}

.exchange-table {
  .table-row {
    .green {
      color: #26c22c;
    }

    .red {
      color: #e53756;
    }
  }
}

@media screen and (max-width: 1100px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}
</style>
