<template>
  <div class="">
    <div class="about-us">
      <div class="about">
        <div class="card">
          <h2>درباره تاپ ننس</h2>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی
            در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را
            می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
            الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
            صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و
            شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد
            استفاده قرار گیرد.
          </p>
        </div>
        <div class="icon">
          <InlineSvg
              :src="require('../../assets/public/aboutus.svg')"
          ></InlineSvg>
        </div>
      </div>
      <div class="analysis">
        <CircularStat :amount=userscount title="تعداد کاربران"/>
        <CircularStat :amount=$toLocal(tradprice,0) title="ارزش معاملات انجام شده"/>
        <CircularStat :amount=totaltrad title="تعداد معاملات"/>
      </div>
      <div class="about-team">
        <h2>تیم تاپ ننس</h2>
        <p>
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
          استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
          ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
          کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی
          در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می
          طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
          الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
          صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
          شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای
          اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده
          قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
          با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
          ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
          کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی
          در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می
          طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
          الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
          صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
          شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای
          اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده
          قرار گیرد.
        </p>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";
import CircularStat from "../tools/CircularStat.vue";

export default {
  name: "AboutUs",
  data() {
    return {
      userscount: '0',
      tradprice: '0',
      totaltrad: '0'
    }
  },
  components: {
    InlineSvg,
    Footer,
    CircularStat,
  },
  methods: {
    async getInfo() {
      const res = await this.$axios('trades/market-info-home')
      this.userscount = res.baseDTO.userCount
      this.tradprice = res.baseDTO.tradesValue
      this.totaltrad = res.baseDTO.tradesAmount

    }
  },
  mounted() {
    this.getInfo()
  }
};
</script>

<style lang="scss" scoped>
.about-us {
  min-height: 65vh;
  background: #0a1128;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .about {
    margin: 100px 120px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      max-width: 595px;
      padding: 50px 86px;
      background: #141b31;
      border-radius: 16px;

      > h2 {
        font-size: clamp(20px, 1.7vw, 40px);
        color: #febc00;
      }

      > p {
        color: white;
        font-weight: 500;
        font-size: clamp(12px, 2vw, 18px);
      }
    }

    .icon {
    }
  }

  .analysis {
    gap: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1440px;
    margin: 100px auto;
    flex-wrap: wrap;
  }

  .about-team {
    margin: 100px 0px;
    background: #141b31;
    border-radius: 16px;

    > h2 {
      padding: 0px 120px;
      color: #febc00;
      font-weight: 500;
      font-size: clamp(20px, 1.7vw, 40px);
    }

    > p {
      padding: 60px 120px;
      font-weight: 500;
      font-size: clamp(14px, 1vw, 18px);
      color: white;
    }
  }
}

@media screen and (max-width: 1300px) {
  .about-us {
    justify-content: center;
    align-items: center;

    .about {
      width: 100%;
      margin: 5px;
      justify-content: center;
      align-items: center;
      padding: 20px;

      .card {
        padding: 5px;
      }

      .icon {
        transform: scale(0.5);
      }
    }

    .analysis {
      margin: 20px auto;
      justify-content: center;
    }

    .about-team {
      width: 90%;
      padding: 5px;

      > h2 {
        padding: 5px;
      }

      > p {
        padding: 5px;
      }
    }
  }
}

@media(max-width: 500px) {
  .about-team {
    margin: 10px 0 !important;
  }
  .icon {
    display: none;
  }
  .analysis {
    .stat {
      min-width: unset;
      min-height: unset;
      width: 150px;
      height: 150px;
    }
  }
}
</style>
