import { render, staticRenderFns } from "./LoginHistory.vue?vue&type=template&id=123a10fa&scoped=true&"
import script from "./LoginHistory.vue?vue&type=script&lang=js&"
export * from "./LoginHistory.vue?vue&type=script&lang=js&"
import style0 from "./LoginHistory.vue?vue&type=style&index=0&id=123a10fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123a10fa",
  null
  
)

export default component.exports