<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <img
            alt=""
            class="close-icon"
            src="../../../assets/profile/closeIcon.svg"
            @click="closeModale"
        />
        <span class="message-title">
          <span v-if="ordertype==='EXCHANGE_SELL'" class="sell">فروش</span>
          <span v-if="ordertype==='EXCHANGE_BUY'" class="buy">خرید</span>
          شما با موفقیت انجام شد
        </span>
        <p class="fac">فاکتور:</p>
        <div class="message">
          <div class="factor">
            <div class="amount">
              <p>مقدار رمز ارز:</p>
              <p>{{ amount }}</p>
            </div>
            <div

                :key="index"
                class="base-price"
            >
              <p>قیمت واحد:</p>
              <p>{{ $toLocal(unitprice, $decimal["TOMAN"]) }}</p>
            </div>
            <div class="income">
              <p v-if="ordertype==='EXCHANGE_BUY'" :class="{'buy' : ordertype==='EXCHANGE_BUY'}"> مقدار دریافتی </p>
              <p v-if="ordertype==='EXCHANGE_SELL'" :class="{'sell' : ordertype==='EXCHANGE_SELL'}">مقدار برداشتی</p>
              <p>{{ totalprice }}</p>
            </div>

          </div>
          <div v-if="ordertype==='EXCHANGE_SELL'" class="icon">
            <InlineSvg
                :src="require('../../../assets/profile/redsuccess.svg')"
            ></InlineSvg>
          </div>
          <div v-if="ordertype==='EXCHANGE_BUY'" class="icon">
            <InlineSvg
                :src="require('../../../assets/profile/greensuccess.svg')"
            ></InlineSvg>
          </div>
        </div>
        <button class="close-btn" @click.prevent="closeModale">بستن</button>
      </div>
    </div>
  </transition>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "exModal",
  components: {
    InlineSvg,
  },
  data() {
    return {
      // close: false,
      amounts: [
        {
          title: "مقدار رمز ارز",
          value: "۰.۰۰۰۴۳ BTC",
        },
      ],
      basePrice: [
        {
          title: "قیمت واحد",
          value: "۲۳۷,۸۸۹,۸۱۳ تومان",
        },
      ],
      income: [
        {
          title: "دریافتی شما",
          value: "۲۳۷,۸۸۹,۸۱۳ تومان",
        },
      ],
    };
  },

  props: ['amount', 'unitprice', 'totalprice', 'ordertype'],
  methods: {
    closeModale() {
      this.$emit("closeModal");
    },
  },
  // created: function () {
  //   window.addEventListener("click", (event) => {
  //     if (!event.target.closest(".modal , .buy")) {
  //       this.$emit("closeModal", this.close);
  //     } else {
  //       return true;
  //     }
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
}

.modal {
  overflow: auto;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;
  min-width: 602px;
  min-height: 252px;
  background: #141b31;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  z-index: 120;
}

.close-icon {
  position: absolute;
  left: 15px;
  top: 17px;
  cursor: pointer;
}

.message-title {
  align-self: flex-start;
  font-weight: bold;
  font-size: 16px;
}

.fac {
  font-size: 12px;
  align-self: flex-start;
  color: #c4c4c4;
  padding-right: 4px;
}

.message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 64px;
  align-items: center;

  .factor {
    display: flex;
    width: 100%;
    row-gap: 14px;
    flex-direction: column;

    .amount,
    .base-price,
    .income {
      display: flex;
      justify-content: space-between;

      > p {
        font-size: 12px;
        display: flex;
      }
    }
  }

  .icon {
  }
}

.close-btn {
  background: #19196f;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .modal {
    overflow: auto;
    width: 90%;
    min-width: 300px;
    height: 350px;

    .message {
      flex-direction: column;
    }
  }
}
</style>
