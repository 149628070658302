<template>
  <div class="sidebar">
    <!--    <vuescroll :ops="ops" class="scroll-bar">-->
    <div class="nav">
      <ul>
        <li
            v-for="(item, index) in navItems"
            :key="index"
            class="nav-list"
            :class="{ active: $route.name === item.link }"
        >
          <span class="icon">
            <InlineSvg
                :src="item.address"
                @click.prevent="$router.push({ name: item.link })"
            ></InlineSvg>
          </span>
          <a
              :href="item.link"
              @click.prevent="$router.push({ name: item.link })"
              id="links"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
    <!--    </vuescroll>-->

    <button class="exit-btn" @click.prevent="modal = true">
      <span>
        <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M4.48664 5.5871L3.32793 3.80878C1.5429 5.06651 0.363277 7.06342 0.0899717 9.29035C-0.183513 11.5171 0.475845 13.7591 1.89975 15.4441C3.32345 17.1293 5.37439 18.095 7.52933 18.095C9.68437 18.095 11.7351 17.1294 13.1589 15.4441C14.5828 13.759 15.242 11.517 14.9687 9.29035C14.6952 7.06342 13.5156 5.06651 11.7306 3.80878L10.5693 5.5871C11.8628 6.49731 12.7177 7.94336 12.9161 9.55608C13.1147 11.169 12.6376 12.7928 11.6064 14.0136C10.5755 15.2344 9.09005 15.934 7.52924 15.934C5.96843 15.934 4.48322 15.2344 3.45207 14.0136C2.42092 12.7929 1.94395 11.169 2.14239 9.55608C2.34083 7.94332 3.19589 6.49731 4.48917 5.5871H4.48664Z"
              fill="white"
              fill-opacity="0.9"
          />
          <path
              d="M6.49023 0.845215H8.55923V7.85054H6.49023V0.845215Z"
              fill="white"
              fill-opacity="0.9"
          />
        </svg>
      </span>
      <span id="exit"> خروج</span>
    </button>
    <LogoutModal v-if="modal" @closeModal="modal = false"/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
// import vuescroll from 'vuescroll';
import LogoutModal from "@/components/profile/LogoutModal.vue";

export default {
  name: "sidebar",
  close: true,
  components: {
    InlineSvg,
    LogoutModal,
  },
  data() {
    return {
      modal: false,
      navItems: [
        {
          title: "داشبورد",
          link: "dashboard",
          address: require("../../assets/profile/icons/dashboard.svg"),
        },
        {
          title: "حساب کاربری",
          link: "Account",
          address: require("../../assets/profile/icons/account.svg"),
        },
        {
          title: "سفارش سریع",
          link: "otc",
          address: require("../../assets/profile/icons/fast.svg"),
        },
        {
          title: "معاملات حرفه ای",
          link: "trade",
          address: require("../../assets/profile/icons/pro.svg"),
        },
        {
          title: "کیف پول",
          link: "wallet",
          address: require("../../assets/profile/icons/wallet.svg"),
        },
        {
          title: "کیف پول صرافی",
          link: "walletEx",
          address: require("../../assets/profile/icons/wallet.svg"),
        },
        {
          title: "تنظیمات",
          link: "settings",
          address: require("../../assets/profile/icons/settings.svg"),
        },
        {
          title: "تاریخچه",
          link: "history",
          address: require("../../assets/profile/icons/history.svg"),
        },
        {
          title: "دعوت از دوستان",
          link: "invite",
          address: require("../../assets/profile/icons/invite.svg"),
        },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 100,
          easing: "easeOutQuad",
          verticalNativeBarPos: "left",
        },
        rail: {
          background: "black",
          opacity: 0,
          size: "5px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "blue",
          opacity: 1,
          size: "10px",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.active {
  > a {
    color: #279bc0;
  }

  > span {
    > svg {
      > path {
        fill: #279bc0;
      }
    }
  }
}

//
//.scroll-bar{
//  min-height: 85vh !important;
//
//}
//
//.__rail-is-vertical {
//  left: 0px !important;
//  right: unset !important;
//
//  opacity: 0.5;
//}
//
//.__bar-wrap-is-vertical {
//  background: #0f1640;
//  opacity: 1;
//}
//.scroll-bar::-webkit-scrollbar {
//  display: none;
//}
.sidebar {
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 60px);
  min-width: 172px;
  background: #0f1640;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .nav {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    row-gap: 38px;

    > ul {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }

    .nav-list {
      overflow: hidden;
      width: 100%;
      display: flex;
      column-gap: 9px;
      align-items: center;

      span {
        margin-right: 13px;
        margin-top: 5px;
      }

      a {
        font-size: clamp(12px, 1vw, 14px);
      }
    }
  }

  .nav::-webkit-scrollbar {
    display: none;
  }
}

.exit-btn {
  color: white;
  font-weight: 500;
  font-size: clamp(14px, 1vw, 18px);
  display: flex;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  min-height: 40px;
  background: #279bc0;

  span {
    margin-top: 6px;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    ul {
      row-gap: 15px !important;
    }
  }
  .sidebar {
    min-width: 50px;
  }
  #links,
  #exit {
    display: none;
  }
}
</style>
