import Vue from "vue";
import App from "./App.vue";
import router from "./library/router";
import store from "./library/store";
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import "./style/index.scss";
import TrendChart from "vue-trend-chart";
import DynamicMarquee from "vue-dynamic-marquee";
import "./library/globalFunctions";
import "./library/state";
import "./library/globalConstants";
import "./library/georgianToJalali";
import "./library/axios";
import "./library/reuseableFunction";
import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies";
import "sweetalert2/dist/sweetalert2.min.css";
import "./style/SweetAlertStyle.scss";
import VueSweetalert2 from "vue-sweetalert2";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'animate.css';
// import "./style/dateTimePicker.scss";

Vue.config.productionTip = false;
Vue.use(TrendChart);
Vue.use(VueApexCharts);
Vue.use(VueCookies);
Vue.use(VueSweetalert2);
Vue.component("date-picker", VuePersianDatetimePicker);
Vue.use(PerfectScrollbar, {
    options: {
        // swipeEasing: false,
        wheelPropagation: false,
    }
})
Vue.component("dynamic-marquee", DynamicMarquee);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
