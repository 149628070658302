<template>
  <div class="pro-trade">
    <div class="trade-info">
      <trading-info :key="$route.params.tradeTo + $route.params.tradeFrom + 'title'"/>
    </div>
    <div class="trading-view">
      <tradingchart :key="0" :index="0"/>
    </div>
    <div class="trading">
      <trade-section :key="$route.params.tradeTo + $route.params.tradeFrom + changeOrder + 'section'"/>
    </div>
    <div class="trading-history">
      <TradingHistory :key="$route.params.tradeTo + $route.params.tradeFrom + state.changeOrder +  'history'"/>
    </div>
  </div>
</template>

<script>
import Tradingchart from "@/components/profile/trade/Tradingchart";
import TradingInfo from "@/components/profile/trade/TradingInfo";
import TradeSection from "@/components/profile/trade/TradeSection";
import TradingHistory from "@/components/profile/trade/tradinghistory/TradingHistory";

export default {
  name: "ProTrade",
  components: {TradeSection, TradingInfo, Tradingchart, TradingHistory},
};
</script>

<style lang="scss" scoped>
.pro-trade {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .trade-info {
    background: var(--blue-bg);
    min-height: 60px;
    border-radius: 8px;
  }

  .trading-view {
    height: 249px;
  }

  .trading-history {
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 400px) {
  .trading-history {
    max-height: 300px;
  }
}
</style>
