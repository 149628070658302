<template>
  <div class="depth">
    <div class="title">
      <p class="buy">خریداران</p>
    </div>
    <div class="table-head">
      <p class="gray">مقدار({{ $coins[tradeFrom].code }})</p>
      <p class="gray" id="price">قیمت واحد({{ $coins[tradeTo].persianName }})</p>
      <p class="gray">قیمت کل({{ $coins[tradeTo].persianName }})</p>
    </div>
    <div v-if="!tableContentsBuy.length">
      <p style="width:100%;text-align:center;color:green">در حال حاضر اطلاعاتی وجود ندارد</p>
    </div>
    <div class="content-container" v-for="(tableContent, index) in tableContentsBuy" :key="index">
      <div class="background" :style="{ width: tableContent.executedPercent + '%' }"></div>
      <div class="content">
        <p>{{ $toLocal(tableContent.volume, $decimal[tradeFrom]) }}</p>
        <p class="text-center" id="price-value">
          {{ $toLocal(tableContent.unitPrice, tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom]) }}</p>
        <p> {{ $toLocal(tableContent.wholePrice, $decimal[tradeTo]) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  tradeTo,
  tradeFrom,
  // precision,
  Loop
} from "@/library/reuseableFunction";

export default {
  name: 'buyDepth',
  data() {
    return {
      tableContentsBuy: [],
      loading: false,
      loop: '',

    }
  },
  computed: {
    tradeFrom,
    tradeTo,
    precision() {
      return this.$decimal[this.tradeFrom] || 8
    }
  },
  methods: {
    async getMarketDepth() {
      // this.state.loading=true
      const [res,] = await this.$http.get('/orders/order-book', {
        params: {
          marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
        }
      })
      this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a, b) => {
        return a.unitPrice > b.unitPrice
      })
      this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
      this.loading = false;
      // this.state.loading=false;
    }
  },
  mounted() {
    this.state.loadng = true;
    this.loading = true
    if (this.state.loop) {
      this.loop = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '-' + this.tradeTo)
      this.loop.start()
    } else {
      this.getMarketDepth()
    }
    this.state.loadng = false;
  },
  beforeDestroy() {
    this.loop.stop()
  },
}
</script>

<style lang="scss" scoped>
.depth {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  padding: 8px 12px;
  position: relative;
  background: #0f1640;
  border-radius: 8px;
  overflow: scroll;
  max-height: 198px;
  min-height: 198px;
}

.title {
  height: 38px;

  p {
    text-align: right !important;
    font-size: 16px;
  }
}

.table-head {
  background: #0f1640 !important;
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 10;
}

//all the content is here
.content {
  align-items: center;
  display: grid;
  width: 100%;
  height: 24px;
  row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 2;

  p {
    z-index: 3;
  }

  p:last-child {
    text-align: left;
  }
}

.background {
  border-radius: 4px;
  position: absolute;
  background-color: rgba(38, 194, 44, 0.5);
  height: 24px;
  left: 0;
  z-index: 0;
}

.content-container {
  position: relative;
  height: 24px;
}

@media (max-width: 860px) {
  #price, #price-value {
    display: none;
  }
  .content {
    grid-template-columns: 1fr 1fr;
  }
}
</style>





