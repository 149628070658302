<template>
  <div id="app">
    <navbar @closeNav="showNav = false"/>
    <dummy/>
<transition
mode="out-in"
 enter-active-class="animate__animated animate__fadeIn"
 leave-active-class = "animate__animated animate__fadeOut"
>
  <router-view/>
</transition>

    <LoadingModal v-if="state.loading"/>
  </div>
</template>

<script>
import Navbar from "./components/sharing-components/Navbar.vue";
import LoadingModal from "@/components/LoadingModal";
// import Dummy from './components/Dummy.vue';

export default {
  components: {LoadingModal, Navbar,},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  max-width: 2000px;
  margin: 0 auto;
}
</style>
