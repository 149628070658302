<template>
  <div class="tables">
    <CancelOrder v-if='cancelModal==true' @close="cancelModal=false" @remove="removeorder"/>
    <table class="btc-table">

      <tr class="tr-head">
        <td class="th">نوع سفارشات</td>
        <td class="th tablet">رمز ارز</td>
        <td class="th small-pc">
          قیمت واحد
          <span>(تومان)</span>
        </td>
        <td class="th small-pc">مقدار</td>
        <td class="th tablet">قیمت کل <span>(تومان)</span></td>
        <td class="th tablet">کارمزد</td>
        <td class="th">زمان</td>
        <td class="th tablet">وضعیت</td>
      </tr>


      <tr
          class="table-row"
          v-for="(tableContent , index) in tableContents" :key="index"

      >
        <td
            class="text negetive"
            :class="{ positive: tableContent.orderType.includes('BUY') , negative:tableContent.orderType.includes('SELL')}"
        >
                           <span v-if="tableContent.orderType.includes('SELL')">
                    فروش
                </span>
          <span v-if="tableContent.orderType.includes('BUY')">
                    خرید
                </span>
        </td>
        <td class="text tablet">

          {{ $coins[tradeFrom].persianName }} -
          {{ $coins[tradeTo].persianName }}

        </td>
        <td class="text small-pc"> {{ $toLocal(tableContent.overallValue, precision) }}</td>
        <td class="text small-pc"> {{ $toLocal(tableContent.amount) }}</td>
        <td class="text tablet">{{ $toLocal(tableContent.overallValue, precision) }}</td>
        <td class="text tablet">{{ state.userInfo.fee }}</td>
        <td class="text"> {{ $G2J(tableContent.createdAtDateTime) }}</td>
        <td class="tablet">

          <img src="@/assets/profile/icons/xmark.svg" v-if="tableContent.orderStatus.includes('IS_OPEN')">
          <img src="@/assets/profile/icons/tick.svg" alt="" v-else>
        </td>
      </tr>
      <div v-if="!tableContents.length">
        <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
      </div>

    </table>
  </div>
</template>

<script>
import {
  tradeTo,
  tradeFrom,
  precision,
  statusColor,
  statusLabel,
  // Loop
} from "@/library/reuseableFunction";

export default {
  name: "historyOrder",
  data() {
    return {
      tableContents: [],
      settings: {
        suppressScrollX: false
      },
      loading: false,
      loop: '',
      page: 0,
      lastPage: 1,
    };
  },
  computed: {
    tradeTo,
    tradeFrom,
    precision,
    statusColor,
    statusLabel
  },
  methods: {
    async getLastOrders() {
      if (this.page <= this.lastPage) {
        //for scroll loading
        this.page++ //for scroll loading
        let res = await this.$axios.get('/orders', {
          params: {
            market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
            page: this.page,
            size: 20
          }
        })
        console.log('orderbook is here', res)
        if (res.content)
          res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
        this.tableContents = res.content;

        this.loading = false
      }
    },
  },
  mounted() {

    this.getLastOrders()


  },
  beforeDestroy() {
    this.loop.stop()
  }
}
</script>

<style lang="scss" scoped>
.tr-head {
  position: sticky;
  top: 0;
  background: #0f1640;

}

.tables {
  position: relative;
  overflow: scroll;
  max-height: 200px;
}

tbody {
  overflow: scroll;
  max-height: 210px;
}

.btc {
  background: #0f1640;
  border-radius: 16px;
  padding: 24px;
  max-height: 213px !important;

  .btns {
    display: flex;
    width: 100%;
    max-width: 280px;
    background: rgba(156, 152, 192, 0.1);
    min-height: 36px;
    border-radius: 8px;

    .diposit,
    .withdrawl {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      color: white;
    }

    .active {
      background: #279bc0;
      border-radius: 8px;
    }
  }

  .table-container {
    height: 70%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: 213px !important;

  }

  .table-container::-webkit-scrollbar {
    display: none;
  }

  .btc-table {
    width: 100%;
    padding: 20px;
    max-height: 200px;
    overflow: auto;

    table {
      max-height: 90%;
      overflow: scroll;
    }

    .th {
      color: #febc00;
      font-size: clamp(12px, 1vw, 16px);
      overflow: auto;

      min-width: 50px;
    }

    .table-row {
      text-align: center;
      padding: 20px;
      height: 50px;
      overflow: scroll;
      max-height: 200px;

      .text {
        font-size: clamp(12px, 1vw, 16px);
      }

      .tablet {
      }

      .phone {
      }

      .small-pc {
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .small-pc {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .trading-history {
    max-height: 350px;
  }
  .btc {
    max-height: 350px;
  }
  .table-container {
    height: 90%;
  }
  .phone {
    display: none;
  }
  .btns {
    width: 90%;
    min-width: 80px;
    max-width: 100px;
  }
}
</style>