<template>
  <div class="dashboard">
    <div class="chart">
      <WalletChart/>
    </div>
    <div class="stat">
      <!-- <div class="row">
        <p>مجموع واریزی</p>
        <p>۱۳،۷۲۵،۰۰۰</p>
      </div> -->

      <!-- <div class="row">
        <p>مانده از اصل</p>
        <p class="gray">۱۳،۷۲۵،۰۰۰</p>
      </div> -->
      <!-- <div class="row">
        <p>سود</p>
        <p class="sell">۱۳،۷۲۵،۰۰۰</p>
      </div> -->
      <div class="row">
        <p>کیف پول تومان</p>
        <p class="gray">{{ $toLocal(cash, $decimal['TOMAN']) }}</p>
      </div>
      <!-- <div class="row">
        <p>در معامله</p>
        <p class="gray">۱۳،۷۲۵،۰۰۰</p>
      </div> -->
      <div class="row">
        <p>قابل برداشت</p>
        <p class="gray">{{ $toLocal(cash, $decimal['TOMAN']) }}</p>
      </div>
    </div>
    <div class="btns">
      <button class="buy-btn" @click.prevent="diposit">واریز</button>
      <button class="sell-btn" @click.prevent="withdrawl">برداشت</button>
    </div>
  </div>
</template>

<script>
import WalletChart from "@/components/profile/WalletChart";

export default {
  components: {WalletChart},
  props: ['role', 'cash'],
  data() {
    return {
      stats: [
        {
          title: "dipositAll",
          amount: "۱۳،۷۲۵،۰۰۰",
        },
        {
          title: "withdrawlAll",
          amount: "۱۳،۷۲۵،۰۰۰",
        },
        {
          title: "remain",
          amount: "۱۳،۷۲۵،۰۰۰",
        },
        {
          title: "reward",
          amount: "۱۳،۷۲۵،۰۰۰",
        },

        {
          title: "walletToman",
          amount: "۱۸۳،۷۲۵،۰۰۰",
        },
        {
          title: "inTrade",
          amount: "۱۸۳،۷۲۵،۰۰۰",
        },
        {
          title: "cashable",
          amount: "۱۷۰،۰۰۰،۰۰۰",
        },
      ],
      modals: {
        diposit: false,
        withdrawl: false,
      },
    };
  },
  methods: {
    //activation diposit button
    diposit() {
      this.modals.diposit = true;
      this.$emit("diposit", this.modals);
    },
    //activation withdrawl button
    withdrawl() {
      this.modals.withdrawl = true;
      this.$emit("withdrawl", this.modals);
    },
  },
};
</script>

<style lang="scss" scoped>
//dashboard in wallet
.dashboard {
  padding: 40px 0;
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  align-items: center;
  min-width: 224px;
  width: 100%;
  border-radius: 8px;
  background: #0f1640;
  max-height: 780px;

  //
  .chart {
  }

  //showing stats
  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    width: 100%;
    //showing the amounts and blah blah...
    .row {
      display: flex;
      justify-content: space-between;
      width: 90%;
    }
  }

  //buttons
  .btns {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    row-gap: 16px;
    min-width: 90%;

    .buy-btn {
      border-radius: 8px;
      min-height: 36px;
      width: 100%;
      background-color: #03c70b;
    }

    .sell-btn {
      border-radius: 8px;
      min-height: 36px;
      background-color: #e53756;
    }
  }
}
</style>
<style lang="scss">
@media (max-width: 629px) {
  .dashboard {
    width: 100% !important;
  }
}
</style>
