<template>
  <div class="">
    <div class="contact-us container">
      <h1>تماس با ما</h1>
      <div class="box">
        <div class="text">
          <p>برای ارتباط با تاپننس از راه های ارتباطی زیر در تماس باشید:</p>
          <div class="info">
            <p>
              <span>
                <InlineSvg
                    :src="require('../../assets/public/phone.svg')"
                ></InlineSvg
                ></span>
              تماس با ما
            </p>
            <p class="center">{{ info.number }}</p>
          </div>
          <div class="info">
            <p>
              <span>
                <InlineSvg
                    :src="require('../../assets/public/email.svg')"
                ></InlineSvg>
                ایمیل
              </span>
            </p>
            <p class="center">{{ info.email }}</p>
          </div>
          <div class="info">
            <p>
              <span>
                <InlineSvg
                    :src="require('../../assets/public/location.svg')"
                ></InlineSvg>
                ادرس
              </span>
            </p>
            <p class="center">{{ info.address }}</p>
          </div>
        </div>
        <div class="icon">
          <InlineSvg :src="require('../../assets/public/contactus.svg')">
          </InlineSvg>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import Footer from "../sharing-components/footer";

export default {
  name: "ContactUs",
  components: {
    InlineSvg,
    Footer,
  },
  data() {
    return {
      info: {
        address: "",
        appAndroid: "",
        appIOS: "",
        email: "",
        instagram: "",
        linkedin: "",
        number: "",
        telegram: "",
        twitter: ""
      },
    };
  },
  methods: {
    async getInfo() {
      const res = await this.$axios.get('/exchange-info')
      this.info = res.baseDTO || {
        address: '',
        appAndroid: '',
        appIOS: '',
        email: '',
        instagram: '',
        linkedin: '',
        number: '',
        telegram: '',
        twitter: ''
      }

    }
  },
  mounted() {
    this.getInfo()
  }
};
</script>

<style lang="scss" scoped>
.contact-us {
  min-height: 65vh;
  width: 100%;

  background: #0a1128;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    color: #febc00;
    font-size: clamp(20px, 1vw, 40px);
    font-weight: 500;
  }

  .box {
    align-self: center;
    min-height: 390px;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px 100px;
    padding-right: 104px;
    padding-top: 54px;
    display: flex;
    border-radius: 16px;
    justify-content: space-between;
    background: #141b31;

    .text {
      color: white;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .info {
        margin-top: 28px;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        > p {
          margin: 0;
          padding: 0;
          color: white;
        }

        .center {
          width: 62%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .contact-us {
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .box {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 20px;
    }
  }
  .icon {
    display: none;
  }
}
</style>
