<template>
  <div class="container">
    <div class="faq">
      <div class="question-mark">
        <img src="../../assets/public/question.svg" alt="">
      </div>
      <div class="faq-boxes">
        <h1 class="title">سوالات متداول</h1>
        <div class="faq-table">
          <div
              class="accordian"
              v-for="(item, index) in faqContent"
              :key="index"
          >
            <button class="btn" @click.prevent="activeAccordian(index)">

              {{ item.question }}
              <svg
                  class="handle"
                  v-if="activeIndex !== index"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M8.39918 13.4695C8.47675 13.585 8.58053 13.6795 8.70161 13.7447C8.82268 13.81 8.9574 13.8441 9.09418 13.8441C9.23096 13.8441 9.36568 13.81 9.48675 13.7447C9.60783 13.6795 9.71161 13.585 9.78918 13.4695L17.3987 2.19781C17.4868 2.0678 17.5384 1.91552 17.548 1.75752C17.5576 1.59952 17.5248 1.44184 17.4532 1.30162C17.3816 1.16139 17.2738 1.04398 17.1417 0.962135C17.0095 0.880294 16.858 0.837154 16.7037 0.837403H1.48468C1.33067 0.838056 1.17974 0.88175 1.04813 0.963787C0.916527 1.04582 0.809215 1.1631 0.737742 1.303C0.666268 1.44291 0.633337 1.60014 0.642488 1.75781C0.65164 1.91547 0.702529 2.06759 0.789682 2.19781L8.39918 13.4695Z"
                    fill="white"
                />
              </svg>
              <inline-svg class="handle" :src="require('../../assets/public/close-acc.svg')"
                          v-if="activeIndex == index"/>
            </button>

            <transition name="fade">
              <div class="answer" v-if="activeIndex == index">
                <p>
                  {{ item.answer }}
                </p>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
// import Accordian from "../tools/Accordian.vue";

export default {
  components: {
    InlineSvg,
    // Accordian,
  },
  data() {
    return {
      activeIndex: null,
      Accordian: [
        {
          id: 1,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 2,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 3,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
        {
          id: 4,
          question: "تاپننس چیست؟",
          show: false,
          answer:
              "   لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده\n" +
              "          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد. لورم\n" +
              "          ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از\n" +
              "          طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و\n" +
              "          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای\n" +
              "          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه\n" +
              "          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با\n" +
              "          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان\n" +
              "          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید\n" +
              "          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به\n" +
              "          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی\n" +
              "          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.\n" +
              "    ",
        },
      ],
      faqContent: []
    };
  },
  methods: {
    activeAccordian(Index) {
      this.activeIndex = this.activeIndex == Index ? null : Index;
    },
    async getFaq() {
      const res = await this.$axios('/faq')
      console.log(res)
      this.faqContent = res;
    }
  },
  mounted() {
    this.getFaq()
  }
};
</script>

<style lang="scss" scoped>
.answer {
  padding: 20px;
}

.accordian {
  column-gap: 15px;
  padding: clamp(8px, 1vw, 16px);
  color: white;
  font-size: clamp(16px, 2vw, 10px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.4px solid rgba(255, 255, 255, 0.5);

  border-radius: 16px;

  .btn,
  .close {
    font-size: clamp(12px, 1vw, 24px);
    font-weight: 500;
    background: transparent;
    border: 0;
    min-width: 100%;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;

    padding: 0px 16px;
  }

  .answer {
    > p {
      font-size: clamp(10px, 2vw, 16px);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

.faq {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  .faq-boxes {
    display: flex;
    flex-direction: column;
    width: 70%;

    .title {
      font-size: (18px, 1vw, 24px);
      color: #febc00;
    }

    .faq-table {
      position: relative;
      width: 100%;
      row-gap: 15px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px;

      background: #141b31;
      border-radius: 8px;
    }
  }

  .question-mark {
    display: flex;
    justify-content: center;
  }
}

@media screen and(max-width: 1399px) {
  .faq {
    padding: 10px;
  }
  .faq-table {
    padding: 0;
  }

  .shadow {
  }

  .question-mark {
    > svg {
      width: 50%;
    }
  }
}

@media screen and(max-width: 1033px) {
  .faq {
    .faq-boxes {
      margin-top: 5px;
      width: 100%;
      padding: 20px;
    }

    .question-mark {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .handle {
    position: absolute;
    left: 25px;
  }
  .btn {
    height: 40px !important;

    svg {
      width: 10px;
    }
  }

}
</style>
