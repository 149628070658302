<template>
  <div class="">
    <div class="market container">
      <div class="page-title">
        <div class="titles">
          <p>بازارها</p>
          <div class="search-bar">
            <input type="text" placeholder="جستجو" v-model="regex"/>
            <button>
              <svg
                  width="27"
                  height="27"
                  viewBox="0 0 30 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.1007 19.9394C13.1081 21.8035 15.7566 22.7873 18.5165 22.694C21.2763 22.6006 23.944 21.4371 25.9857 19.4362C28.0274 17.4352 29.2926 14.7444 29.5282 11.902C29.7638 9.0597 28.9524 6.27544 27.2563 4.1063C25.5603 1.93716 23.1046 0.543108 20.3807 0.203043C17.6568 -0.137022 14.8654 0.60198 12.5651 2.2722C10.2648 3.94242 8.62517 6.42069 7.97425 9.21118C7.32334 12.0017 7.70913 14.8986 9.05446 17.3224L9.05467 17.3207C8.98154 17.3669 8.91134 17.4169 8.84198 17.474L1.60302 23.5902C1.25023 23.8881 1.0209 24.3134 0.965475 24.7725C0.910051 25.2317 1.03307 25.6871 1.30747 26.0385C1.58187 26.39 1.98517 26.6088 2.42866 26.6467C2.87215 26.6846 3.31949 26.5385 3.67227 26.2407L10.9112 20.1244C10.9792 20.0677 11.0431 20.0059 11.1024 19.9396L11.1007 19.9394ZM17.4737 20.933C16.2662 20.8302 15.1001 20.4822 14.042 19.9088C12.984 19.3354 12.0547 18.5479 11.3072 17.5912C10.5597 16.6345 10.0087 15.5274 9.68564 14.333C9.36256 13.1387 9.27372 11.8805 9.4242 10.6303C9.57469 9.38011 9.96154 8.16239 10.5627 7.04669C11.1638 5.93099 11.9675 4.93915 12.9278 4.1278C13.8881 3.31644 14.9862 2.70147 16.1594 2.31799C17.3326 1.93451 18.558 1.79002 19.7656 1.89279C22.2044 2.10035 24.4225 3.3024 25.9321 5.23453C27.4417 7.16665 28.119 9.67057 27.8151 12.1955C27.5112 14.7203 26.2509 17.0594 24.3115 18.698C22.3722 20.3366 19.9125 21.1405 17.4737 20.933Z"
                    fill="#9C9FC0"
                />
              </svg>
            </button>
          </div>
        </div>
        <img src="../../assets/public/market.png" alt="market"/>
      </div>
      <!-- <CryptoTable/> -->
      <market-table :regex='regex'/>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import CryptoTable from "@/components/home-page/CryptoTable";
import Footer from "@/components/sharing-components/footer";
import MarketTable from "./MarketTable.vue";

export default {
  name: "Market",
  data() {
    return {
      regex: ''
    }
  },
  components: {
    // CryptoTable
    Footer,
    MarketTable,

  },
};
</script>

<style lang="scss" scoped>
.market {
  padding: 6% 0;
}

.title {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.page-title {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .titles {
    max-width: 453px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .search-bar {
      padding: 2px 10px;
      margin-top: 10px;
      margin-bottom: 12px;
      width: 100%;
      max-width: 310px;
      max-height: 40px;
      justify-content: center;
      align-items: center;
      border: 1px solid #279bc0;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;

      > input {
        background: transparent;
        border: 0;
        width: 100%;
      }

      > button {
        background: transparent;
        border: 0;

        > svg {
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }

    > p {
      font-size: clamp(20px, 2vw, 40px);
      color: #febc00;
    }
  }
}

@media (max-width: 600px) {
  .search-bar {
    width: 50% !important;
  }
  .page-title {
    img {
      display: none;
    }
  }

}
</style>
