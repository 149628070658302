<template>
  <div class="trade-menu">
    <div class="btns">
      <button
          class="diposit"
          @click.prevent="limit=true"
          :class="{ active: limit }"
      >
        حد
      </button>
      <button
          class="withdrawl"
          @click.prevent="limit=false"
          :class="{ active: !limit }"
      >
        بازار
      </button>
    </div>
    <div class="inputs">
      <sell-menu :limit='limit' :cash="sellCredit" :wage="state.userInfo.fee" :decimal="8" :id="2" :key="'sell'+limit"
                 @refreshInventory='getWalletCredit' :role="role"/>
      <buy-menu :limit='limit' :cash="buyCredit" :wage="state.userInfo.fee" :decimal="8" :id="1" :key="'buy'+limit"
                @refreshInventory='getWalletCredit' :role="role"/>
    </div>
  </div>
</template>

<script>

import '@/style/dateTimePicker.scss'
import sellMenu from "@/components/profile/trade/shop/sellMenu";
import buyMenu from "@/components/profile/trade/shop/buyMenu";
import {tradeTo, tradeFrom} from "@/library/reuseableFunction";

export default {

  name: "TradeMenu",
  components: {
    sellMenu,
    buyMenu
  },
  data() {
    return {
      limit: true,
      sellCredit: 0,
      buyCredit: 0,
      role: {}
    };
  },
  computed: {
    tradeTo,
    tradeFrom
  },
  methods: {
    activeBtn() {
      if (this.diposit) {
        this.withdrawl = true;
        this.diposit = false;
      } else {
        this.diposit = true;
        this.withdrawl = false;
      }
    },
    async getWalletCredit() {
      this.state.loading = true
      if (this.state.userInfo.authorized) {
        const res = await this.$axios.get('/wallets/customer-addresses?walletType=P2P', {
          params: {
            coins: `${this.tradeFrom},${this.tradeTo}`

          }
        })
        console.log(res)
        this.sellCredit = res[this.$route.params.tradeFrom].credit || 0
        this.buyCredit = res[this.$route.params.tradeTo].credit || 0

        //get role
        const coins = await this.$axios('/coins')
        this.role = coins.content.find((a) => a.coin === this.tradeFrom)
        console.log(this.role)
        this.state.loading = false


      }
    },
    closemodal() {
      this.showmodal = false
    },
    tuochHandler(e) {
      if (e === 'right') {
        this.limit = false
      } else {
        this.limit = true
      }
    }
  },
  created() {
    this.getWalletCredit()
  }

};
</script>

<style lang="scss" scoped>

input[type='text'] {
  color: white;
  padding: 0 15px;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid #279bc0;
  border-radius: 4px;
  background: transparent;
}

//layout styles
.trade-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px 8px 16px 8px;
  width: 100%;
  min-height: 417.5px;
  height: 100%;
  border-radius: 16px;
  background-color: var(--blue-bg);
}

//limit and bazar buttons
.btns {
  display: flex;
  width: 100%;
  max-width: 280px;
  background: rgba(156, 152, 192, 0.1);
  min-height: 36px;
  border-radius: 8px;

  .diposit,
  .withdrawl {
    width: 100%;
    border-radius: 8px;
    background: transparent;
    color: white;
  }

  .active {
    background: #279bc0;
    border-radius: 8px;
  }
}

//inputs represents trade market menu
.inputs {
  display: flex;
  gap: 24px;

  //2 columns of the market menu box
  .buy-column,
  .sell-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 48%;
    row-gap: 20px;
  }
}

//first-row box in sell and buy box that includes 3 el
//firs input and inventory and base price
.first-row {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  //inventory shows the volume radio button and inventory
  .inventory {
    display: flex;
    column-gap: 18px;
    align-items: flex-start;
    //the row that contains prices and range under price
    .inventory-row {
      display: flex;
      gap: 4px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      a {
        display: flex;

        img {
          align-self: center;
        }
      }
    }
  }

  //controlling the input range
  .range-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    input {
      width: 100%;
    }

    label {
      font-size: 8px;
    }
  }

  //teter input
  .teter {
    display: flex;
    flex-direction: column;

    .teter-row {
      font-size: 12px;
    }
  }
}

//a box that contains limit amount and submit
.second-row {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .total-price {
    color: var(--gray-color);
    border: 1px solid #279ac077;
    border-radius: 4px;
    padding: 8px 15px;

    span {
      font-size: 12px;
    }
  }
}

@media (max-width: 1324px) {
  .inventory {
    flex-direction: column !important;
  }

  @media (max-width: 1068px) {
    .trade-menu {
      max-width: none;
    }
  }
  @media (max-width: 502px) {
    .inputs {
      flex-wrap: wrap;
    }
  }
}
</style>

