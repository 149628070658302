<template>
  <div class="chart-container">
    <apexchart
        type="donut"
        width="100%"
        :options="chartOptions" :series="series" :key="series.length + 'P2P' + chartOptions.labels.length + change"
    ></apexchart>
    <!-- <div class="background"></div> -->
    <div class="shadow"></div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      balance: null,
      statistics: '',
      max: null,
      p2p: true,
      open: '',
      contents: [],
      contentsExchange: [],
      chartData: '',
      chartDataExchange: '',
      series: [0],
      seriesExchange: [0],
      change: 0,
      wallets: {},
      walletsExchange: {},
      chartOptions: {
        chart: {
          width: '50px',
          fontFamily: 'iransansweb',
        },
        colors: [
          '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
        ],
        theme: {
          mode: 'light',
          palette: 'palette2'
        },
        stroke: {
          width: 1,
          colors: undefined
        },
        labels: [],
        dataLabels: {
          style: {
            fontFamily: 'iransansweb'
          }
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: '80%',
              background: '#001E3D',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  offsetY: -17,
                  formatter: function (val) {
                    return val.name || val
                  }
                },
                value: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 400,
                  color: '#F2F2F2',
                  offsetY: 10,
                  formatter: function (val) {
                    return parseFloat(val).toLocaleString()
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'ارزش کل دارایی',
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  formatter: function (w) {
                    w = w.globals.seriesTotals.reduce((a, b) => a + b)
                    return w.toLocaleString() + '\n' + 'تومان'
                  }
                }
              }
            },
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          custom: function ({
                              seriesIndex,
                              w
                            }) {
            return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
          }
        }
      }, chartOptionsExchange: {

        responsive: [
          {
            breakpoint: 1330,
            options: {
              chart: {
                width: "100%",
              },

              plotOptions: {
                pie: {
                  donut: {
                    size: "80%",
                  },
                  total: {
                    fontSize: "4px",
                  },
                },
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                width: "100%",
              }
            }
          }
        ],
        chart: {
          width: '50px',
          fontFamily: 'iransansweb',
        },
        colors: [
          '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
        ],
        theme: {
          mode: 'light',
          palette: 'palette2'
        },
        stroke: {
          width: 1,
          colors: undefined
        },
        labels: [],
        dataLabels: {
          style: {
            fontFamily: 'iransansweb'
          }
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: '70%',
              background: '#001E3D',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  offsetY: -17,
                  formatter: function (val) {
                    return val.name || val
                  }
                },
                value: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 400,
                  color: '#F2F2F2',
                  offsetY: 10,
                  formatter: function (val) {
                    return parseFloat(val).toLocaleString()
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'ارزش کل دارایی',
                  fontSize: '12px',
                  fontFamily: 'iransansweb',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  formatter: function (w) {
                    w = w.globals.seriesTotals.reduce((a, b) => a + b)
                    return w.toLocaleString() + '\n' + 'تومان'
                  }
                }
              }
            },
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          custom: function ({
                              seriesIndex,
                              w
                            }) {
            return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
          }
        }
      },
    }
  },
  methods: {
    async getWalletData() {
      const res = await this.$axios(`/wallets/customer-addresses?walletType=P2P`)
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contents = entireRes
      this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
      this.wallets = entireRes
    },
    async getWalletDataExchange() {
      const res = await this.$axios(`/wallets/customer-addresses?walletType=EXCHANGE`)
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contentsExchange = entireRes
      this.chartDataExchange = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
      this.walletsExchange = entireRes
    },
    chart() {
      let index = 0
      this.wallets.forEach((e) => {
        this.chartOptions.labels[index] = {
          'name': this.$coins[e[0]].persianName,
          'value': e[1].credit || 0
        }
        this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
        index++
      })
      ++this.change
    },
    chartExchange() {
      let index = 0
      this.walletsExchange.forEach((e) => {
        this.chartOptionsExchange.labels[index] = {
          'name': this.$coins[e[0]].persianName,
          'value': e[1].credit || 0
        }
        this.seriesExchange[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
        index++
      })
      ++this.change
    },
    routing(e) {
      if (this.$route.params.tradeTo !== e)
        this.$router.push('/dashboard/' + this.$route.params.tradeTo + '/' + e)
    },
    goto(e) {
      this.$router.push('trade/' + 'TOMAN' + `/${e}`)
    },

  },
  async mounted() {
    this.state.loading = true
    await this.getWalletData()
    await this.getWalletDataExchange()
    this.chart()
    this.chartExchange()
    this.state.loading = false
    const res = await this.$axios.get('/users/invites')
    this.statistics = res.baseDTO
  },
  computed: {
    credit() {
      return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].credit.toFixed(0) : e[1].credit) || 0
    }
  }
};
</script>
<style lang="scss" scoped>
.shadow {
}

.chart-container {
}

//@media only screen and(max-width:1130px) {
//  .chart-container {
//    position: relative;
//    width: 40%;
//    display: flex;
//    justify-content: center;
//  }
//  .background {
//    width: 103px;
//    height: 103px;
//    border-radius: 200px;
//    position: absolute;
//
//    box-shadow: inset 0px 4px 4px rgba(0, 119, 204, 0.2);
//    box-sizing: border-box;
//    top: 20px;
//    left: 40px;
//    opacity: 1;
//    // padding: 20px;
//    z-index: 20;
//  }
//}
</style>
