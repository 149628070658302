<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <img
            @click="closeModale"
            class="close-icon"
            src="../../../assets/profile/closeIcon.svg"
            alt=""
        />
        <span class="message-title"> واریز {{ $coinLabel[relatedCoin[0]] }}  </span>
        <div class="message">
          <div class="msg">
            <p>آدرس کیف پول شما در زیر قابل مشاهده است.</p>
            <p>
              برای واریز {{ $coinLabel[relatedCoin[0]] }} به این کیف پول، می‌توانید از این آدرس استفاده
              کنید.
            </p>
          </div>
          <template>
            <label  class="selectd-net-lable">
              <div class="selectd-net" @click="drop = !drop">
                <span v-if="selected_net">{{ selected_net }}</span>
                <span v-else>انتخاب شبکه</span>
              </div>
              <div class="dropdown" v-if="drop">
                <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                  {{ net.tokenType }}
                </div>
              </div>
            </label>
          </template>
          <div class="warning" v-if="selected_net">
            <span
            ><svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_539_5498)">
                  <path
                      d="M12.9154 0C10.7233 0 8.58048 0.645139 6.75783 1.85383C4.93517 3.06253 3.51459 4.78049 2.67571 6.79048C1.83683 8.80047 1.61735 11.0122 2.045 13.146C2.47266 15.2798 3.52825 17.2398 5.07829 18.7782C6.62833 20.3166 8.6032 21.3642 10.7532 21.7886C12.9031 22.2131 15.1316 21.9952 17.1569 21.1627C19.1821 20.3301 20.9131 18.9202 22.1309 17.1113C23.3488 15.3023 23.9988 13.1756 23.9988 11C23.9957 8.08359 22.8269 5.28753 20.7491 3.22531C18.6712 1.16309 15.854 0.00315432 12.9154 0V0ZM12.9154 20.1667C11.0887 20.1667 9.30298 19.629 7.7841 18.6218C6.26522 17.6146 5.08139 16.1829 4.38233 14.5079C3.68327 12.8329 3.50036 10.9898 3.85674 9.21167C4.21312 7.43351 5.09278 5.80017 6.38448 4.51819C7.67618 3.23621 9.32191 2.36317 11.1135 2.00947C12.9052 1.65577 14.7623 1.8373 16.45 2.5311C18.1377 3.22491 19.5801 4.39982 20.595 5.90727C21.6099 7.41472 22.1516 9.187 22.1516 11C22.1489 13.4303 21.175 15.7604 19.4434 17.4789C17.7119 19.1974 15.3642 20.164 12.9154 20.1667Z"
                      fill="#E63756"
                  />
                  <path
                      d="M12.9158 4.5835C12.6708 4.5835 12.4359 4.68007 12.2627 4.85198C12.0895 5.02389 11.9922 5.25705 11.9922 5.50016V12.8335C11.9922 13.0766 12.0895 13.3098 12.2627 13.4817C12.4359 13.6536 12.6708 13.7502 12.9158 13.7502C13.1608 13.7502 13.3957 13.6536 13.5689 13.4817C13.7421 13.3098 13.8394 13.0766 13.8394 12.8335V5.50016C13.8394 5.25705 13.7421 5.02389 13.5689 4.85198C13.3957 4.68007 13.1608 4.5835 12.9158 4.5835Z"
                      fill="#E63756"
                  />
                  <path
                      d="M13.8394 16.5002C13.8394 15.9939 13.4259 15.5835 12.9158 15.5835C12.4057 15.5835 11.9922 15.9939 11.9922 16.5002C11.9922 17.0064 12.4057 17.4168 12.9158 17.4168C13.4259 17.4168 13.8394 17.0064 13.8394 16.5002Z"
                      fill="#E63756"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_539_5498">
                    <rect width="23.9984" height="22" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
            <p>
              واریز ارز دیگری جز {{ $coinLabel[relatedCoin[0]] }} موجب از دست رفتن دارایی شما خواهد شد
            </p>
          </div>
          <div class="qr" v-if="selected_net">
            <img :src="'data:image/png;base64,'+qrCode" alt="" width="200" height="200"/>
          </div>
          <div class="address" v-if="selected_net">
            <div class="addressess">
              <p>آدرس کیف پول</p>
              <div class="msg" v-if="alertLink">آدرس کپی شد</div>
            </div>
            <div class="wallet-address">
              <button @click.prevent="copyLink">
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 4.03535L5 3.03696H10.414L14 6.61719V14.0193L13 15.0177H5L4 14.0193V4.03535ZM13 7.03053L10 4.03535H5V14.0193H13V7.03053Z"
                      fill="white"
                  />
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 1.04016L2 2.03855V12.0225L3 13.0209V2.03855H9.414L8.414 1.04016H3Z"
                      fill="white"
                  />
                </svg>
              </button>
              <p>{{ address }}</p>
            </div>
            <div class="check">
              <p class="error-p" v-if="!enable && !!selected_net">
                در حال حاضر واریز به شبکه مورد نظر مقدور نیست
              </p>
              <input class="normal-input" type="text" v-if="txIdIsNeeded" v-model="txid" placeholder="شناسه تراکنش">
              <button class="btn buy-btn transaction" @click.prevent="checkWallet"
                      :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''">
                بررسی واریز
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ExitModal",
  props: {
    relatedCoin: {
      default: ''
    },
    exchange: {
      default: false
    },
    nets: {
      default: {}
    }
  },

  data() {
    return {
      amount: '',
      address: '',
      qrCode: '',
      tag: '',
      txid: '',
      token: false,
      alertLink: false,
      drop: false,
      selected_net: "",
      txIdIsNeeded: false,
      enable: false
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.address);
      this.alertLink = true
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    closeModale() {
      this.$emit('closeModaleEmit', this.close)
    },
    changeNetwork(e) {
      this.token = e
      this.getQR()
    },
    Copy(e) {
      navigator.clipboard.writeText(e)
      alert('آدرس کپی شد')
    },
    async checkWallet() {
      this.state.loading = true
      // const token = this.token ? 'ERC20' : 'TRC20'
      let query = {
        relatedCoin: this.relatedCoin[0],
        tokenType: this.selected_net,
        walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
      }
      if (this.txIdIsNeeded) {
        query.txid = this.txid
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },
    selectFun(index) {
      this.selected_net = this.nets[index].tokenType
      this.txIdIsNeeded = this.nets[index].txIdIsNeeded
      this.enable = this.nets[index].isDepositEnable || true
      this.drop = false
      if (this.enable) {
        this.getQR()
      }


    },
    async getQR() {
      this.state.loading = true
      const token = this.selected_net
      let params = {
        relatedCoin: this.relatedCoin[0]
      }
      if (this.relatedCoin[0] === 'TETHER') {
        params.tokenType = token
      }
      const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
        params
      }).catch(() => {
        this.closeModale()
      })
      if (res) {
        this.address = res.baseDTO.address
        this.qrCode = res.baseDTO.qrCode
        this.tag = res.baseDTO.tag
      }
    }
  },
  // mounted() {
  //   this.getQR()
  // }


  // created: function () {
  //   window.addEventListener("click", (event) => {
  //     if (!event.target.closest(".modal , .buy")) {
  //       this.$emit("closeModal", this.close);
  //     } else {
  //       return true;
  //     }
  //   });
  // },
}
</script>

<style lang="scss" scoped>
.transaction {
  width: 100% !important;
}

.btn {
  margin-top: 5px;
}

.qr {
  img {

    border-radius: 4px;
  }
}

.check {
  button {
    align-self: center;
  }
}

.selectd-net-lable {
  position: relative;
  z-index: 2;

  .selectd-net {
    cursor: pointer;
    background: transparent;
    border: 1px solid #279bc0;
    border-radius: 4px;
    height: 38px;
    width: 100%;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  .dropdown {
    position: absolute;
    width: 100%;
    top: 48px;
    background: #001E3D !important;
    border: 1px solid #279bc0;
    border-radius: 4px;
    padding: 10px;

    div {
      cursor: pointer;
    }
  }
}


.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  overflow: auto;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;

  min-height: 276px;
  background: #141b31;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 16px;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.message-title {
  font-weight: bold;
  font-size: 16px;
  color: #febc00;
}

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  .msg {
    > p {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .warning {
    display: flex;
    column-gap: 5px;

    > p {
      max-width: 90%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.qr {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.address {
  display: flex;

  row-gap: 16px;
  flex-direction: column;

  > p {
    font-size: 16px;
    font-weight: 400;
  }

  .addressess {
    display: flex;
    justify-content: space-between;
  }

  .wallet-address {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border: 1px solid #279bc0;
    border-radius: 4px;
    padding: 0 15px;

    > p {
      color: #9c9fc0;
    }

    > button {
      background: transparent;
      display: flex;
      align-items: center;
    }

    .msg {
      position: absolute;
      left: 0;
      bottom: 0;
      top: -50px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    overflow: auto;
    width: 90%;
    min-width: 300px;
    height: 90%;
  }
}
</style>
