<template>
  <div class="items">
    <div class="first-column">
      <div class="coins" v-for="(item, index) in wallets.filter(e => e[0]!=='TOMAN')" :key="index">
        <div class="symbol">
          <img :src="'/coins/'+ item[0] + '.png' " :alt="item[0]" width="28" height="28"/>
          <p>{{ $coins[item[0]].persianName }}</p>
          <p class="brand">{{ $coins[item[0]].code }}</p>
        </div>
        <div class="inventory">
          <div class="inv">
            <p class="key">موجودی</p>
            <p class="value">
              {{ $toLocal(item[1].credit, 0) }}
              {{ $coins[item[0]].persianName }} </p>
          </div>
          <div class="val">
            <p class="key">ارزش</p>
            <p class="value">
              {{ $toLocal(item[1].wholePriceOfCredit, 2) }}
              تومان
            </p>
          </div>
        </div>
        <div class="btns">
          <button class="buy" @click="openDepModal([item[0], credit(item[1])] , index)">واریز</button>
          <button class="sell" @click="openWithModal([item[0], credit(item[1])] , index)">برداشت</button>

        </div>
      </div>
    </div>
    <DipositModal v-if="modal==='DepositBit'" @closeModaleEmit="closeModal" :related-coin="relatedCoinVar" :cash="cash"
                  @two-fa="openTwoStepModal" :wage="wage" :nets="nets" :exchange='false'/>
    <WidthdrawlModal :related-coin="relatedCoinVar" :cash="cash" :role="role" :wage="wage"
                     v-if="modal==='WithdrawalBit'" @closeModaleEmit="modal=''" @two-fa="openTwoStepModal" :nets="nets"
                     :exchange='false'/>
    <SuccessModal v-if="modal==='success'" :msgType="msg" @closeModaleEmit="closeModal"/>
    <RialDipositModal v-if="modal==='DepositeToman'" @closeModaleEmit="closeModal" :cash="tomancash" :role="role"/>
    <RialwithdrawlModal v-if="modal==='WidthdrawalToman'" @closeModaleEmit="closeModal" :cash="tomancash"
                        @two-fa="openTwoStepModal" :role="role"/>
  </div>
</template>

<script>
import DipositModal from "./DipositModal.vue";
import WidthdrawlModal from "./WidthdrawlModal.vue";
import RialDipositModal from "@/components/profile/wallet/RialDipositModal";
import RialwithdrawlModal from "@/components/profile/wallet/RialwithdrawlModal";
import SuccessModal from './SuccessModal.vue';

export default {
  name: "CoinsWallet",
  components: {
    DipositModal,
    WidthdrawlModal,
    RialDipositModal,
    RialwithdrawlModal,
    SuccessModal,

  },
  data() {
    return {
      msg: {
        wit: false,
        dipo: false,
      },

      buttons: [{
        title: "برداشت",
        class: "withdrawal-btn",
        open: 'WithdrawalBit'
        //  click : () => this.deposit()
      },
        {
          title: "واریز",
          class: "deposite-btn",
          open: 'DepositBit'
        },
        {
          title: "معامله",
          class: "trade-btn",
          link: "trade"
        }
      ],
      tomanBTN: [{
        title: "واریز",
        class: "deposite-toman",
        open: 'DepositeToman'
      },
        {
          title: "برداشت",
          class: "widthdrawal-toman",
          open: 'WidthdrawalToman'
        }
      ],
      nav: [{
        title: "ارز"
      },
        {
          title: "موجودی",
          title2: "معادل تومان"
        },
        {
          title: "عملیات"
        }
      ],
      showWithModal: false,
      showDepModal: false,
      showRWithModal: false,
      showRDepModal: false,
      wallets: [],
      relatedCoinVar: '',
      cash: 0,
      tomancash: 2,
      twoStep: '',
      transaction: {},
      coins: [],
      wage: '',
      role: {},
      loading: false,
      modal: ''

    }
  },
  computed: {
    amount() {
      return e => this.$toLocal(e.credit) || '0'
    },
    price() {
      return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].totalBalance : e[1].wholePriceOfCredit, 0) || '0'
    },
    blocked() {
      return e => this.$toLocal(e[1].blockedCredit) || '0'
    },
    credit() {
      return e => this.$toLocal(e.credit) || '0'
    },
  },

  methods: {
    passData() {
      this.$emit('passRole', this.role)
      this.$emit('passToman', this.tomancash)
    },

    OpenModal(index, cash) {
      this.cash = cash
      this.wage = this.coins.find((a) => a.coin === "TOMAN").transferFee
      this.role = this.coins.find((a) => a.coin === "TOMAN")
      this.modal = this.tomanBTN[index].open
    },
    OpenModalBit(e, index) {
      this.modal = this.buttons[index].open
      this.relatedCoinVar = e
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
      this.role = this.coins.find((a) => a.coin === e[0])
    },
    openWithModal(e) {
      this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
      this.relatedCoinVar = e[0]
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
      this.role = this.coins.find((a) => a.coin === e[0])
      this.modal = 'WithdrawalBit'

    },
    openDepModal(e) {

      this.relatedCoinVar = e
      // this.cash = e[1]
      // this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
      this.modal = 'DepositBit'
      this.nets = this.coins.find((a) => a.coin === e[0]).tokenTypeDetails
    },
    closeModal(value) {
      this.modal = value
    },
    depositModal(e) {
      this.relatedCoinVar = e
      if (e[0] !== 'TOMAN') {
        this.showDepModal = true
      } else {
        this.showRDepModal = true
      }
    },
    withdrawModal(e) {
      this.relatedCoinVar = e[0]
      this.cash = e[1]
      this.wage = this.coins.find((a) => a.coin === e[0]).transferFee
      if (e[0] !== 'TOMAN') {
        this.showWithModal = true
      } else {
        this.showRWithModal = true
      }
    },
    openTwoStepModal(e) {
      this.transaction = e[0]
      this.twoStep = e[1]
    },
    closemodal() {
      this.showWithModal = false
      this.showDepModal = false
      this.showRWithModal = false
      this.showRDepModal = false
      this.twoStep = ''
    },
    async getWallets() {
      this.state.loading = true
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')

      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes
        this.tomancash = res["TOMAN"].credit
        console.log('response', res)

      }
      this.loading = false

    },
    async submitWithdrawRequest(code) {
      this.state.loading = true
      this.transaction.code = code
      const [res,] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
      if (res) {
        this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
        ++this.state.requestChanged
        this.closemodal()
      }
    },
    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content

    },
    async checkWallet(e) {
      let query = {
        relatedCoin: e
      }
      if (e === 'TETHER') {
        const res = await this.$error('', 'شبکه مورد نظر را انتخاب کنید', 'info', 'اتریومی', 'ترونی')
        if (res.dismiss !== 'backdrop') {
          query.tokenType = res.isConfirmed ? 'ERC20' : 'TRC20'
        } else {
          return;
        }
      }
      if (this.$etcChannelCoin.includes(e)) {
        query.txid = await prompt('TxId : شناسه تراکنش')
        if (!query.txid) {
          return;
        }
      }
      this.state.loading = true
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        ++this.state.requestChanged
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[e]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },
    goto(e) {
      this.$router.push('/profile/trade/' + 'TOMAN' + `/${e}`)
    },

  },
  async mounted() {
    this.state.loading = true
    await this.getWallets()
    await this.getData()
    console.log(this.toman);
    await this.passData()
  },
};
</script>

<style lang="scss" scoped>
.first-column,
.second-column {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 780px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  // max-width: 664px;

  gap: 8px;

  .coins {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 16px 12px;
    width: 100%;
    max-width: 327px;
    height: 190px;
    background: #0f1640;
    border-radius: 8px;

    .symbol {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .brand {
        color: #febc00;
      }
    }

    .inventory {
      display: flex;
      row-gap: 12px;
      flex-direction: column;
      width: 100%;

      .inv,
      .val {
        display: flex;
        justify-content: space-between;

        .value {
          color: #9c9fc0;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: space-between;
      width: 100%;
      column-gap: 8px;

      .buy,
      .sell {
        width: 100%;
        min-width: 148px;
        min-height: 36px;
        border-radius: 8px;
        color: white;
        font-size: 16px;
      }

      .buy {
        background: #26c22c;
      }

      .sell {
        background: #e63756;
      }
    }
  }
}


@media (max-width: 629px) {
  .coins {
    max-width: unset;
    min-width: 100% !important;

  }
  .items {
    width: 100% !important;
    max-width: unset;
  }
  .first-column, .second-column {
    width: 100% !important;
  }

}

@media screen and (max-width: 400px) {
  .items {
    //width: 90%;

    .coins {
      width: 100%;
      max-width: unset;

      .btns {
        .buy,
        .sell {
          min-width: unset;
          width: 100%;
        }
      }
    }
  }
}


</style>
