var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading"},[_c('vue-trading-view',{key:_vm.tradeFrom,staticStyle:{"height":"430px"},attrs:{"options":{
                        symbol: _vm.tradeFrom !== 'TETHER' ? 'BINANCE:' + _vm.$coinUnit[_vm.tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
            height:'249px',
            width:'100%',
           theme:'dark',
                    timezone: 'Asia/Tehran',
                    style: '1',
                    hide_top_toolbar:true,
                    hide_legend:true,
                    hide_bottom_toolbar:false,
                    hide_legend:false,
                    locale: 'fa_IR',
                    enable_publishing: false,
                    withdateranges: true,
                    range: 'ytd',
                    hide_side_toolbar: true,
                    allow_symbol_change: true,
                    enabled_features: [
                        'header_fullscreen_button'
                        ],
                    disabled_features: [
                        'border_around_the_chart',
                        'remove_library_container_border' ],
                    loading_screen:{
                        backgroundColor:'#F2F6FF',
                        foregroundColor: '#F2F6FF',
                        },
                    overrides: {
                        background: '#F2F6FF'
                    }
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }