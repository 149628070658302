<template>
  <div class="trading-info">
    <ul>
      <li>
        <span> بازار: </span>

        <span>
          {{ $coins[tradeFrom].persianName }} -
          {{ $coins[tradeTo].persianName }}
          </span>
      </li>
      <li>
        <span> بیشترین: </span>
        <span class="buy">{{ $toLocal(data.maxPrice, precision) }}</span>
      </li>
      <li>
        <span> کمترین: </span>

        <span class="sell"> {{ $toLocal(data.minPrice, precision) }}</span>
      </li>
      <li>
        <span>آخرین: </span>
        <span>{{ $toLocal(data.lastPrice, precision) }}</span>
      </li>
      <li>
        <span>حجم: </span>
        <span>{{ $toLocal(data.last24HourVolume, precision) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  Loop,
  tradeTo,
  tradeFrom,
  precision,
} from "@/library/reuseableFunction";
import Crypto from "../../../library/CryptoTrade";

export default {
  name: "TradingInfo",
  data() {
    return {
      coins: Crypto,
      headercoin: null,
      loading: false,
      toman: true,
      loop: "",
      data: {
        marketType: "",
        maxPrice: 0,
        minPrice: 0,
        lastPrice: 0,
        last24HourVolume: 0,
        last24HourChange: 0,
      },
    };
  },
  computed: {
    tradeFrom,
    tradeTo,
    precision,
  },
  methods: {
    async getTradeHeader() {
      const [res] = await this.$http.get("trades/market-info", {
        params: {
          weeklyDataIncluded: false,
          market_type:
              this.$route.params.tradeFrom + "_" + this.$route.params.tradeTo,
        },
      });
      this.loading = false;
      if (res) {
        this.data = res.content[0] || this.data;
        this.state.shopInfo.lastPrice = this.data.lastPrice;
        this.state.shopInfo.change24 = this.data.last24HourChange;
        document.title =
            `بازار ${this.$coinLabel[this.tradeFrom]} - ${
                this.$coinLabel[this.tradeTo]
            }` +
            " ｜ " +
            this.$toLocal(this.data.lastPrice, this.precision);
      }
    },
  },
  mounted() {
    this.headercoin = this.coins[1];

    this.loading = true;
    document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${
        this.$coinLabel[this.tradeTo]
    }`;
    if (this.state.loop) {
      this.loop = new Loop(
          this.getTradeHeader,
          this.state.time,
          "tradeHeader:" + this.tradeFrom + "-" + this.tradeTo
      );
      this.loop.start();
    } else {
      this.getTradeHeader();
    }
  },
  beforeDestroy() {
    this.loop.stop();
  },
};
</script>

<style lang="scss" scoped>
.trading-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  > ul {
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
<style></style>
